import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {AppointmentStatusEnum} from '@shipwell/tempus-sdk';
import {StatusToColor, SvgIconColors, SvgIconNames} from '../../constants';
import {translateReferenceType} from '../../utils';
import {AppointmentEntry, AppointmentReference, Filters, ViewMode} from 'App/data-hooks/appointments/types';
import './AppointmentCalendarEvent.scss';
import {toTitleCase} from 'App/utils/string';

interface AppointmentIconStyleOptions {
  color?: string;
  size?: string;
  className?: string;
}

interface AppointmentIconProps {
  appointment: AppointmentEntry;
  styleOptions?: AppointmentIconStyleOptions;
}

const AppointmentIcon: React.FC<AppointmentIconProps> = ({appointment, styleOptions}) => {
  const {status} = appointment;
  const iconName = SvgIconNames[status];
  const color = styleOptions?.color || SvgIconColors[status];
  const size = styleOptions?.size || '16';
  const additionalClasses = styleOptions?.className || '';

  return iconName ? (
    <SvgIcon className={`${additionalClasses}`} color={color} name={iconName} width={size} height={size} />
  ) : null;
};

interface AppointmentHeaderProps {
  appointment: AppointmentEntry;
}

const AppointmentHeader: React.FC<AppointmentHeaderProps> = ({appointment}) => {
  return (
    <div className="flex items-center justify-start">
      <AppointmentIcon appointment={appointment} />
      <span>{appointment.carrierName}</span>
    </div>
  );
};

interface AppointmentBodyProps {
  references: AppointmentReference[];
  divCalendar?: {
    width: number;
    height: number;
  };
  filters?: Filters;
}

export const AppointmentBody: React.FC<AppointmentBodyProps> = ({references, filters, divCalendar}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleReferences, setVisibleReferences] = useState<AppointmentReference[]>([]);
  const [hiddenCount, setHiddenCount] = useState(0);

  useEffect(() => {
    if (containerRef.current && divCalendar?.height) {
      const container = containerRef.current;
      const allReferences = filters
        ? references.filter((ref) => filters.preferredReference[ref?.qualifier] === true)
        : references;

      const visible: AppointmentReference[] = [];
      let totalHeight = 0;
      let countHidden = 0;

      allReferences.forEach((reference) => {
        const tempElement = document.createElement('span');
        tempElement.className = `sw-appointment-reference ${reference.qualifier.toLowerCase()}`;
        tempElement.style.visibility = 'hidden';
        tempElement.style.whiteSpace = 'nowrap';
        tempElement.style.overflow = 'hidden';
        tempElement.style.textOverflow = 'ellipsis';
        tempElement.innerText = `${translateReferenceType(reference.qualifier)}: ${reference.value}`;
        container.appendChild(tempElement);

        const elementHeight = tempElement.offsetHeight;

        // Adjust for spacing and ensure we account for additional items
        if (totalHeight + elementHeight + 4 <= divCalendar.height - 16) {
          visible.push(reference);
          totalHeight += elementHeight + 4; // Adjusted for margin/padding
        } else {
          countHidden += 1; // Increment hidden count for each excluded item
        }

        container.removeChild(tempElement);
      });

      setVisibleReferences(visible);
      setHiddenCount(countHidden);
    } else {
      setVisibleReferences(references);
      setHiddenCount(0);
    }
  }, [references, filters, divCalendar]);

  const styleReference: React.CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: divCalendar && divCalendar.width ? `${divCalendar.width - 10 - (hiddenCount > 0 ? 30 : 5)}px` : undefined
  };

  return (
    <div ref={containerRef} className="flex flex-col">
      {visibleReferences.map((reference, index) => (
        <div key={index} className="flex items-center gap-1">
          <span
            className={`sw-appointment-reference ${reference.qualifier.toLowerCase()} shrink-0 text-xs`}
            style={styleReference}
          >
            {`${translateReferenceType(reference.qualifier)}: ${reference.value}`}
          </span>
          {index === visibleReferences.length - 1 && hiddenCount > 0 && (
            <span
              className="ml-2 shrink-0 text-xs"
              style={{
                maxWidth: '30px',
                overflow: 'hidden'
              }}
            >
              {`+${hiddenCount}`}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

interface AppointmentCalendatTooltipProps {
  appointment: AppointmentEntry;
  divCalendar?: {
    width: number;
    height: number;
  };
}

export const AppointmentCalendatTooltip: React.FC<AppointmentCalendatTooltipProps> = ({appointment, divCalendar}) => {
  const {references} = appointment;
  const start = moment(appointment.start.timestamp).tz(appointment.start.timezone);
  const end = moment(appointment.end.timestamp).tz(appointment.end.timezone);
  const formattedTimeRange = `(${start.format('HH:mm')} - ${end.format('HH:mm')} ${start.format('z')})`;

  const styleTooltip: React.CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: divCalendar?.width ? `${divCalendar.width}px` : undefined
  };
  return (
    <div style={styleTooltip} className="flex items-start justify-start">
      <div className="col-span-1 flex">
        <AppointmentIcon appointment={appointment} styleOptions={{color: 'white', size: '25'}} />
      </div>
      <div className="col-span-9 flex flex-col pl-4">
        <span className="font-bold">
          {`${toTitleCase(appointment.status)}`} <span className="font-normal">{formattedTimeRange}</span>{' '}
        </span>
        <span className="italic">{appointment.carrierName}</span>
        <AppointmentBody references={references || []} />
        <span className={`sw-appointment-reference`}>
          {`Check In: ${
            appointment?.checkedInAt
              ? moment(appointment.checkedInAt.timestamp)
                  .tz(appointment.checkedInAt.timezone)
                  .format('YYYY-MM-DD HH:mm')
              : '---'
          }`}
        </span>
        <span className={`sw-appointment-reference`}>
          {`Check Out: ${
            appointment?.checkedOutAt
              ? moment(appointment.checkedOutAt.timestamp)
                  .tz(appointment.checkedOutAt.timezone)
                  .format('YYYY-MM-DD HH:mm')
              : '---'
          }`}
        </span>
      </div>
    </div>
  );
};

export function AppointmentCalendarEntry(props: {
  appointment: AppointmentEntry;
  viewMode: ViewMode;
  filters?: Filters;
}) {
  const {appointment, viewMode, filters} = props;
  const {status, scheduledResourceReferenceId, durationMs, references} = appointment;

  const rootClasses = [
    `fc-event-main-frame`,
    `sw-appointment`,
    `sw-appointment-status-${status}`,
    'rounded',
    'text-sw-text',
    'flex',
    'px-[8px]',
    'py-[8px]',
    'w-full',
    'shadow-lg'
  ];

  const isShortAppointment = durationMs <= 22.5 * 60 * 1000 || appointment.allDay;

  if (isShortAppointment) {
    rootClasses.push(`sw-appointment-short`);
  }
  rootClasses.push(viewMode === ViewMode.Week ? 'sw-appointment-week-view' : 'sw-appointment-day-view');

  const divRef = useRef<HTMLDivElement>(null);
  const [divCalendar, setDivCalendar] = useState({
    width: 0,
    height: 0
  });

  useEffect(() => {
    const element = divRef.current;

    const updateDivDimensions = () => {
      if (element) {
        const rect: DOMRect = element.getBoundingClientRect();
        setDivCalendar({
          width: rect.width,
          height: rect.height
        });
      }
    };

    updateDivDimensions();

    // Check dimensions every 200ms
    const intervalId = setInterval(updateDivDimensions, 200);

    // Cleanup the interval on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      ref={divRef}
      className={classNames(rootClasses, StatusToColor[status], {
        'text-xxs flex-col': !isShortAppointment,
        'text-[8px] flex-row items-center gap-px leading-[10px] px-[2px]': isShortAppointment,
        'border-1 border-sw-border-alternate': AppointmentStatusEnum.Unscheduled === status
      })}
    >
      <Tooltip
        placement="top"
        tooltipClassname={`max-w-[${divCalendar.width * 1.5}] p-2`}
        trigger={filters ? 'hover' : undefined}
        tooltipContent={<AppointmentCalendatTooltip appointment={appointment} />}
      >
        <div className="flex flex-col">
          <AppointmentHeader appointment={appointment} />
          {!references?.length ? (
            <span className="sw-appointment-shipment-id">{scheduledResourceReferenceId}</span>
          ) : (
            <AppointmentBody divCalendar={divCalendar} references={references} filters={filters} />
          )}
        </div>
      </Tooltip>
    </div>
  );
}
