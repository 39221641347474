import {array, object, string, mixed} from 'yup';
import {RulesEnum} from '../logicalConstraint/ruleSelect/rulesEnum';
import {Tag} from '@shipwell/corrogo-singlerequestparam-sdk';

/**
 * Validation schema for logical constraints.
 * - `firstValue` represents the left operand.
 * - `secondValue` represents the right operand.
 */
export const validationSchema = object({
  constraints: array().of(
    object({
      /**
       * `firstValue` (left operand)
       * - Must be `null` for unary operators (`IGNORE` or `INDIVIDUALLY`).
       * - Must be a non-empty array for binary operators.
       */
      firstValue: mixed().when('rule', {
        is: (rule: RulesEnum) => [RulesEnum.ignore, RulesEnum.individually].includes(rule),
        then: mixed()
          .nullable()
          .test('is-null', 'For unary operators, the left operand must be null', (value) => value === null),
        otherwise: array()
          .min(1, 'Left operand cannot be empty')
          .test('is-array', 'First operand must be an array', (value) => {
            return Array.isArray(value);
          })
      }),
      rule: string()
        .required('Rule is required')
        .test('valid-rule', 'Rule must be a valid RulesEnum value', (value) =>
          Object.values(RulesEnum).includes(value as RulesEnum)
        ),
      /**
       * `secondValue` (right operand)
       * - Must be a non-empty array
       * - Cannot overlap with the left operand.
       */
      secondValue: array()
        .min(1, 'Right operand cannot be empty')
        .test('disjoint-sets', 'Left and right operands cannot have overlapping tags', function (secondValue) {
          const firstValue = Array.isArray(this.parent.firstValue) ? this.parent.firstValue : [];
          const secondArray = Array.isArray(secondValue) ? secondValue : [];
          const firstSlugs = firstValue.map((tag: Tag) => tag.slug);
          const secondSlugs = secondArray.map((tag: Tag) => tag.slug);
          const hasOverlap = firstSlugs.some((slug: string) => secondSlugs.includes(slug));
          return !hasOverlap;
        })
    })
  )
});
