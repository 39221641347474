import Select from 'react-select';
import FormGroup from '../../formGroup';
import {useMemo} from 'react';

import './styles.scss';

/**
 * Select Field
 */
const SelectField = (props) => {
  const {
    input,
    req,
    placeholder,
    options,
    simpleValue,
    labelKey,
    valueKey,
    clearable,
    disabled,
    multi,
    field,
    ...rest
  } = props;
  const value = input.value || field.value || props.value;
  const name = input.name || field.name || props.name;
  const onChange = input.onChange || field.onChange || props.onChange;

  // react-select v5 requires an item from the array to
  // correctly display the selected value, unlike v1,
  // which only needed a string that matched the valueKey.
  const currentValue = useMemo(() => {
    // "simpleValue" is a concept used in the previous version
    // that indicates the value will be only the key. Therefore,
    // we need to dig into the options array to find the corresponding item.
    if (simpleValue) {
      return options?.find((option) => option[valueKey] === value) || null;
    }

    // If it's not a "simpleValue", we can use the value itself,
    // which will likely match one of the option items.
    return value;
  }, [value, simpleValue, options]);

  function handleChange(newValue) {
    if (onChange) {
      if (simpleValue && newValue) onChange(newValue[valueKey]);
      else onChange(newValue);
    }
  }

  return (
    <FormGroup {...props} className="select-group">
      {() => (
        <Select
          {...rest}
          isMulti={multi}
          name={name}
          createOptionPosition="first"
          classNamePrefix="sw-react-select"
          isClearable={clearable}
          isDisabled={disabled}
          placeholder={placeholder}
          value={currentValue}
          options={options}
          getOptionLabel={(option) => option[labelKey]}
          getOptionValue={(option) => option[valueKey]}
          onChange={handleChange}
          menuPortalTarget={document.body}
        />
      )}
    </FormGroup>
  );
};

SelectField.defaultProps = {
  name: '',
  value: '',
  input: {},
  field: {},
  labelKey: 'name',
  valueKey: 'id',
  clearable: false
};

export default SelectField;
