import {Component} from 'react';
import AsyncCreatable from 'react-select/async-creatable';
import FormGroup from '../../formGroup';
import {validateEmail} from 'App/utils/globals';
import {getAddressBookPromise} from 'App/actions/addressBook';

/**
 * Email Search Field
 */
class EmailSearchField extends Component {
  static defaultProps = {
    placeholder: '',
    multi: true,
    autoload: true,
    value: '',
    name: '',
    input: {}
  };

  constructor() {
    super();
    this.searchAddressBook = this.searchAddressBook.bind(this);
    this.isValidNewOption = this.isValidNewOption.bind(this);
  }

  /**
   * Search emails from address book by query
   * @param  {String} value Search input value
   */
  async searchAddressBook(value) {
    try {
      const response = await getAddressBookPromise({query: value});
      return this.filterEmailResults(response);
    } catch (err) {
      console.log(err);
    }

    return [];
  }

  /**
   * Normalize email results from address book response
   * @param  {Object} resp Address Book api response
   * @return {Array}       List of unique email addresses
   */
  filterEmailResults(resp) {
    const emailSet = new Set();
    const emails = [];

    if (resp && resp.results) {
      resp.results.forEach((result) => {
        result.point_of_contacts.forEach(({email}) => {
          if (!emailSet.has(email)) {
            emailSet.add(email);
            emails.push({label: email, value: email});
          }
        });
      });
    }
    return emails;
  }

  /**
   * Prompt text for creatable value
   * @param  {String} value Input value
   * @return {Boolean}       Prompt text
   */
  isValidNewOption(value) {
    const hasCommaOrSimicolon = /(,|;)/.test(value);
    const isValid = validateEmail(value) || hasCommaOrSimicolon;
    return isValid;
  }

  render() {
    const {input, placeholder, multi, ...rest} = this.props;
    const value = input.value || rest.value;
    const name = input.name || rest.name;
    const onChange = input.onChange || rest.onChange;

    return (
      <FormGroup {...this.props}>
        {() => (
          <AsyncCreatable
            {...rest}
            classNamePrefix={'sw-react-select'}
            isMulti={multi}
            name={name}
            value={value}
            placeholder={placeholder}
            loadOptions={this.searchAddressBook}
            isValidNewOption={this.isValidNewOption}
            onChange={(value) => {
              if (onChange) {
                onChange(value);
              }
            }}
          />
        )}
      </FormGroup>
    );
  }
}

export default EmailSearchField;
