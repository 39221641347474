import {Button, Card, CollapsibleCardContent, DeprecatedButton, SvgIcon, Title} from '@shipwell/shipwell-ui';
import {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';
import {LogicalConstraint} from './logicalConstraint/logicalConstraint';
import {LogicalConstraintError} from './logicalConstraint/logicalConstraintValue';
import {useConstraintsService} from './useConstraintsService/useConstraintsService';
import {useConstraintsForm} from './useConstraintsForm/useConstraintsForm';

export interface LogicalConstraintsCardRef {
  validate: () => Promise<boolean>;
  save: (companyId: string) => Promise<void>;
  resetForm: () => void;
}

export interface LogicalConstraintsCardProps {
  companyId?: string;
  onDirty: (isDirty: boolean) => void;
}

export const LogicalConstraintsCard = forwardRef<LogicalConstraintsCardRef, LogicalConstraintsCardProps>(
  (props, ref) => {
    const onDirtyRef = useRef(props.onDirty);
    onDirtyRef.current = props.onDirty;

    const service = useConstraintsService({
      companyId: props.companyId
    });

    const form = useConstraintsForm({
      values: service.data
    });

    useImperativeHandle(ref, () => ({
      validate: async () => {
        const errors = await form.validateForm();
        return Object.keys(errors).length === 0;
      },
      save: async (companyId) => {
        await service.save({companyId, values: form.values.constraints});
      },
      resetForm: () => {
        form.resetForm();
      }
    }));

    useEffect(() => {
      onDirtyRef.current(form.dirty);
    }, [form.dirty]);

    return (
      <Card
        title="Logical Constraints"
        isCollapsible
        draggableProvided={null}
        actions={
          <div className="flex items-center gap-2">
            {service.loading && <SvgIcon name="LoadingDots" role="progressbar" />}
            <DeprecatedButton
              variant="tertiary"
              icon={<SvgIcon name="AddCircleOutlined" />}
              onClick={form.addConstraint}
            >
              Add Rule
            </DeprecatedButton>
          </div>
        }
      >
        <CollapsibleCardContent>
          <div className="flex flex-col gap-2">
            {!form.values.constraints.length && (
              <div className="my-4 flex flex-col items-center justify-center gap-2">
                <Title variant="emptyStateHeader">No Logical Constraints</Title>
                <Button variant="secondary" size="sm" onClick={form.addConstraint}>
                  Add Rule
                </Button>
              </div>
            )}

            {form.values.constraints.map((value, idx) => (
              <LogicalConstraint
                key={idx}
                number={idx + 1}
                value={value}
                error={form.errors.constraints?.[idx] as LogicalConstraintError}
                onChange={(newValue) => {
                  form.updateConstraintAt(idx, newValue);
                }}
                onRemove={() => {
                  form.removeConstraintAt(idx);
                }}
              />
            ))}
          </div>
        </CollapsibleCardContent>
      </Card>
    );
  }
);

LogicalConstraintsCard.displayName = 'LogicalConstraintsCard';
