/* global $, google */
import _ from 'lodash';
import {Banner} from '@shipwell/shipwell-ui';
import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Field, reduxForm, change, SubmissionError} from 'redux-form';
import {Row, Col, Form, Button, Modal, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import {useFlags} from 'launchdarkly-react-client-sdk';

import {bind} from 'App/utils/camelize';
import PhoneNumberField from 'App/formComponents/phoneNumberField';
import TruckNumberField from 'App/formComponents/fields/truckNumber';
import renderAsyncSelect from 'App/formComponents/renderAsyncSelect';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import * as actions from 'App/actions/shipments';
import * as carrierActions from 'App/actions/vendors';

import SearchVendor from 'App/containers/Settings/ManageVendors/SearchVendor';
import Vendor from 'App/containers/Settings/ManageVendors/Vendor';

import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import DriverFields from 'App/containers/Book/FormSections/DriverFields';
import './_carrier.scss';
import {permCreateCarrierRelationship, permViewCarriers} from 'App/utils/globals';

var Scroll = require('react-scroll');
var Element = Scroll.Element;

export class Carrier extends Component {
  constructor(props) {
    super(props);

    bind(this, ['getVendors', 'createVendor', 'handleOnComplete', 'renderVendorModal', 'switchScreen', 'closeModal']);

    this.state = {
      showCarrierSearchModal: false,
      carrierUser: null,
      cell: null,
      currentTags: [],
      currentPOC: [],
      currentInput: '',
      currentTag: '',
      errorDetails: '',
      filterValue: '',
      groupToAddTo: [],
      keyboard: false,
      newCarrier: false,
      saving: false,
      searchError: null,
      searchValue: '',
      showError: false,
      updated: false,
      usdotSearch: '',
      currentScreen: 'search',
      pagination: {
        page: 1,
        pageSize: 25,
        ordering: 'company',
        q: ''
      },
      tagsPagination: {
        page: 1,
        pageSize: 10000,
        ordering: 'name'
      },
      canSelectVendor: false,
      showPOCField: false,
      vendorPOCs: []
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.company)) {
      if (
        this.props.user &&
        this.props.user.permissions &&
        this.props.user.permissions.includes('carrier_relationships.view_carrier_relationships')
      ) {
        //then this is a broker
        this.setState({canSelectVendor: true});
      }
    }
  }

  componentWillMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.company && nextProps.company !== this.props.company) {
      if (
        nextProps.user &&
        nextProps.user.permissions &&
        nextProps.user.permissions.includes('carrier_relationships.view_carrier_relationships')
      ) {
        //then this is a broker
        this.setState({canSelectVendor: true});
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formValues.values &&
      this.props.formValues.values &&
      this.props.formValues.values.carrier &&
      prevProps.formValues.values.carrier !== this.props.formValues.values.carrier
    ) {
      //check if this vendor has more than one potential POC
      //if the selected vendor has more than one POC, we show the POC field so they can select the person that will be working the shipment
      //get the details of this vendor by ID

      this.props.searchForCarrierByID(this.props.formValues.values.carrier.value).then((response) => {
        if (response.status === 200) {
          if (
            response.details &&
            response.details.results &&
            response.details.results[0] &&
            response.details.results[0].point_of_contacts &&
            response.details.results[0].point_of_contacts.length > 1
          ) {
            this.props.setDefaultVendorPOC(null);
            //show the POC field when form is open
            this.setState({
              showPOCField: true,
              vendorPOCs: response.details.results[0].point_of_contacts
            });
          } else if (
            response.details &&
            response.details.results &&
            response.details.results[0] &&
            response.details.results[0].point_of_contacts &&
            response.details.results[0].point_of_contacts.length === 1
          ) {
            this.props.setDefaultVendorPOC(response.details.results[0].point_of_contacts[0]);
            //when there is only one POC, auto-assign it when the form is submitted.
            this.setState({
              defaultVendorPOC: response.details.results[0].point_of_contacts[0].id,
              showPOCField: false,
              vendorPOCs: []
            });
          } else {
            this.props.setDefaultVendorPOC(null);
            this.setState({showPOCField: false, vendorPOCs: []});
          }
        }
      });
      //User removed carrier, remove POC
    } else if (prevProps?.formValues?.values?.carrier && !this.props?.formValues?.values?.carrier) {
      this.props.setDefaultVendorPOC(null);
    }
  }

  createVendor() {
    this.props.clearSelectedCarrier();
    this.props.clearCarriers();
    this.props.clearSelectedRelationship();
    this.setState({
      showCarrierSearchModal: true
    });
  }

  getVendors(input) {
    const pagination = this.state.pagination;
    if (input) {
      pagination.q = input;
      pagination.pageSize = null;
    }
    return this.props.getCarrierRelationships(pagination).then((response) => {
      if (response.status === 200) {
        // add a field to determine if an option should be disabled in the renderAsyncSelect component
        const {details} = response;
        const resultsWithDisabledField = details.results.map((carrier) => ({
          ...carrier,
          disabled: carrier?.carrier_status !== 'ACTIVE'
        }));
        details.results = resultsWithDisabledField;
        return details;
      }
      return [];
    });
  }

  handleOnComplete(vendor) {
    this.props.changeVendor(vendor.shipwell_vendor);
    this.setState({
      showCarrierSearchModal: false,
      currentScreen: 'search'
    });
  }

  switchScreen(screen) {
    this.setState({currentScreen: screen});
  }

  closeModal() {
    this.setState({showCarrierSearchModal: false});
  }

  renderVendorModal() {
    if (this.state.currentScreen === 'manual') {
      return <Vendor isManual showInModal handleOnComplete={this.handleOnComplete} closeModal={this.closeModal} />;
    }
    if (this.state.currentScreen === 'new') {
      return <Vendor showInModal handleOnComplete={this.handleOnComplete} closeModal={this.closeModal} />;
    }
    return <SearchVendor showInModal switchScreen={this.switchScreen} />;
  }

  render() {
    const {canViewCarriers, canCreateCarriers, selectedCarrier, user} = this.props;

    const pocOptions = this.state.vendorPOCs.map((e) => {
      return {id: e.id, name: e.first_name + ' ' + (e.last_name ? e.last_name : '')};
    });
    let carrier = null;

    if (selectedCarrier && selectedCarrier.vendor && selectedCarrier.vendor.shipwell_vendor) {
      carrier = selectedCarrier.vendor.shipwell_vendor.carrier;
    }

    return (
      <div className="quote-flow__carrier">
        <h2 className="border-bottom">
          Carrier
          {canCreateCarriers && (
            <Button
              bsStyle="link"
              type="button"
              onClick={this.createVendor}
              className="btn-tertiary add-vendor"
              name="add-vendor"
            >
              <i className="flaticon-plus pad-right" /> Create Carrier
            </Button>
          )}
        </h2>
        {this.state.canSelectVendor ? (
          <div className="quote-flow__carrierEquipment">
            <Element name={`position-carrier`} />
            <Element name={`position-driver_cell`} />
            <Field
              component={renderAsyncSelect}
              cache={false}
              getOptions={this.getVendors}
              defaultValue={this.props.formValues?.values?.carrier}
              name="carrier"
              label="Carrier"
              valueField="shipwell_vendor.id"
              labelField="shipwell_vendor.name"
              placeholder="Search carrier by name, USDOT, or MC #"
              defaultOptions
            />
            {this.state.showPOCField && !this.props.is_quoting_limited_user && (
              <Field
                component={renderDropDownSelector}
                data={pocOptions}
                name="vendor_point_of_contact"
                label="Point of Contact"
                placeholder="Select a point of contact from the carrier"
                onChange={(e, value) => {
                  // set selected POC in newShipment state for tender
                  const selected = pocOptions.find((poc) => poc.id === value);
                  this.props.setDefaultVendorPOC(selected);
                }}
              />
            )}
            <DriverFields
              carrier={this.props?.formValues?.values?.carrier}
              oldDriverField={
                this.props.showDriverField ? (
                  <Field
                    verifyNumber
                    component={PhoneNumberField}
                    name="driver_cell"
                    label="Driver Cell Phone (for shipment tracking)"
                    placeholder="Enter Cell #"
                  />
                ) : null
              }
            />

            {this.props.formValues?.values?.carrier?.value && carrier?.eld_providers?.length > 0 ? (
              <Field carrier={carrier} name="power_unit" component={TruckNumberField} />
            ) : null}
          </div>
        ) : (
          <div className="quote-flow__carrierEquipment">Permission required to view carrier relationship</div>
        )}
        <InfoModalWrapper
          show={this.state.showCarrierSearchModal}
          bsSize="large"
          onHide={() => {
            this.setState({
              showCarrierSearchModal: false,
              currentScreen: 'search'
            });
          }}
          title="Create Carrier"
          children={this.renderVendorModal()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    company: state.auth.company,
    equipmentTypes: state.shipments.equipmentTypes,
    carrierRelationships: state.vendors.carrierRelationships,
    serviceLevels: state.shipments.serviceLevels,
    shipmentModes: state.shipments.shipmentModes,
    selectedCarrier: state.vendors.selectedCarrier,
    selectedRelationship: state.vendors.selectedRelationship,
    carrierTags: state.vendors.carrierTags,
    canViewCarriers: !!state.auth?.user?.permissions?.includes(permViewCarriers),
    canCreateCarriers: !!state.auth?.user?.permissions?.includes(permCreateCarrierRelationship)
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      ...carrierActions
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Carrier);
