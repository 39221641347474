import {Component} from 'react';
import {FormGroup, ControlLabel, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import {v4} from 'uuid';

function normalizeSelections(item) {
  if (item[0]?.id) {
    //this is a new item
    return item[0].id;
  }
  if (typeof item[0] === 'string') {
    return item[0];
  }
  if (item.length === 0) {
    return null;
  }
}

class renderTypeahead extends Component {
  constructor() {
    super();
    this.state = {
      id: v4()
    };
  }

  render() {
    const {
      name,
      allowNew,
      clearButton,
      disabled,
      emptyLabel,
      icon,
      input,
      label,
      labelKey,
      newSelectionPrefix = 'Create new entry:',
      clearOnFocus = true,
      nolabel,
      options,
      placeholder,
      req,
      selected,
      selectHintOnEnter,
      sronly,
      tooltip,
      onSelect,
      meta: {touched, error}
    } = this.props;

    // selected should be an array without undefined values
    let selectedValue = selected;
    if (!Array.isArray(selectedValue) || (Array.isArray(selectedValue) && typeof selectedValue[0] === 'undefined')) {
      selectedValue = [];
    }

    return (
      <FormGroup validationState={touched && error ? 'error' : null}>
        {label ? (
          <ControlLabel className={sronly && 'sr-only'}>
            {label}
            {req && <span className="requiredField">*</span>}
            {tooltip && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">
                    <div>{tooltip}</div>
                  </Tooltip>
                }
              >
                <a tabIndex="-1">
                  <i className="icon icon-Info" aria-hidden="true" />
                </a>
              </OverlayTrigger>
            )}
          </ControlLabel>
        ) : nolabel ? null : (
          <ControlLabel>&nbsp;</ControlLabel>
        )}
        {tooltip && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <div>{tooltip}</div>
              </Tooltip>
            }
          >
            <a tabIndex="-1">
              <i className="icon icon-Info" aria-hidden="true" />
            </a>
          </OverlayTrigger>
        )}

        <InputGroup className="input-group-inline">
          {icon && (
            <InputGroup.Addon>
              <i className={icon} />
            </InputGroup.Addon>
          )}
          <Typeahead
            id={this.state.id}
            align="left"
            allowNew={allowNew}
            className={!icon && 'form-control-no-icon'}
            clearButton={clearButton ? clearButton : false}
            disabled={disabled}
            flip
            highlightOnlyResult={!allowNew}
            labelKey={labelKey}
            options={options}
            selected={selectedValue}
            onChange={(selected) => {
              input.onChange(normalizeSelections(selected));
              onSelect && onSelect(selected);
            }}
            ref={(ref) => (this._typeahead = ref)}
            newSelectionPrefix={newSelectionPrefix}
            placeholder={touched && error ? error : placeholder}
          />
        </InputGroup>
        {touched && error && (
          <span className="error-text">
            <i className="icon icon-Delayed" />
            {error}
          </span>
        )}
      </FormGroup>
    );
  }
}

export default renderTypeahead;
