import moment from 'moment';
import {DateTimePicker} from 'react-widgets';
import FormGroup from '../../formGroup';
import {formatHrMin, formatHrMinSec} from 'App/utils/globals';

/**
 * Time Picker Field
 */
const TimePickerField = (props) => {
  const {input, req, step = 30, calendar = false, date = false, placeholder = 'Select Time', ...rest} = props;
  const value = input.value || props.value;
  const onChange = input.onChange || props.onChange;
  let timeValue;
  if (moment(value, formatHrMinSec).isValid()) {
    timeValue = moment(value, formatHrMinSec).toDate();
  }
  //Since we need to allow users to not type in leading zeroes, we convert
  //the leading zero of the time string for display purposes.
  //This still gets converted to a date (from a string)
  //by the onChange function
  const timePickerFormat = 'H:mm';

  return (
    <FormGroup {...props}>
      {() => (
        <DateTimePicker
          {...rest}
          name={input.name}
          value={timeValue}
          defaultValue={timeValue}
          step={step}
          calendar={calendar}
          date={date}
          timeFormat={timePickerFormat}
          format={'H:mm'}
          placeholder={placeholder}
          onChange={(value) => onChange(value)}
        />
      )}
    </FormGroup>
  );
};

TimePickerField.defaultProps = {
  name: '',
  value: '',
  input: {}
};

export default TimePickerField;
