import moment, {duration} from 'moment';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import {
  StopType,
  BaseAddressSchema,
  OptimizeOrderSchema,
  StopSchema,
  PlanSchema,
  LoadSchema,
  ActionType,
  LoadSchemaModeEnum,
  OrderResourceType
} from '@shipwell/shipment-assembly-sdk';
import {PurchaseOrder, OrderItem, OrderStop} from '@shipwell/corrogo-sdk';
import {PurchaseOrderLineItemResponse, PurchaseOrderResponse, ShipmentMode} from '@shipwell/backend-core-sdk';
import {MAP_FTL_COLOR, MAP_LTL_COLOR} from '../constants';
import {convertKilogramsToPounds, KILOGRAMS} from 'App/utils/internationalConstants';
import {getDateObjectFromDateString} from 'App/utils/dateTimeGlobals';
import {BulkOrderActions} from 'App/containers/purchaseOrders/list/components/selectedPurchaseOrdersListActions';
import {mapOrderItemsToLineItemsSchema} from 'App/containers/orders/views/OrdersListView/components/OrdersListTable/SelectedOrderActionsPopover/mappers';
import {getOrderStopReferencesObject} from 'App/containers/orders/utils';
/**
 * Returns a list of stops that are not arranged in proper chronological order
 */

export const getUnchronologicalStops = (stops: Array<StopSchema>) => {
  return stops.filter((stop, stopIndex, shipmentStops) => {
    return (
      isStopEndTimeAfterNextStopStartTime(stop, stopIndex, shipmentStops) ||
      isStopStartTimeBeforePreviousStopEndTime(stop, stopIndex, shipmentStops)
    );
  });
};

export const isStopEndTimeAfterNextStopStartTime = (
  stop: StopSchema,
  stopIndex: number,
  shipmentStops: Array<StopSchema>
) =>
  shipmentStops[stopIndex + 1] &&
  moment(stop?.planned_end_time).isAfter(moment(shipmentStops[stopIndex + 1].planned_start_time));

export const isStopStartTimeBeforePreviousStopEndTime = (
  stop: StopSchema,
  stopIndex: number,
  shipmentStops: Array<StopSchema>
) =>
  shipmentStops[stopIndex - 1] &&
  moment(stop?.planned_start_time).isBefore(moment(shipmentStops[stopIndex - 1].planned_end_time));

/**
 * Takes a list of order line items and returns a total weight in pounds
 */
export const getTotalOrderWeightPounds = (lineItems: PurchaseOrderLineItemResponse[]) => {
  return lineItems.reduce((totalWeight: number, lineItem) => {
    const lineItemWeightPounds =
      lineItem?.weight_unit === KILOGRAMS
        ? Number(convertKilogramsToPounds(lineItem?.total_line_item_weight))
        : lineItem?.total_line_item_weight || 0;
    return isNumber(lineItem?.total_line_item_weight) ? totalWeight + lineItemWeightPounds : totalWeight;
  }, 0);
};

const validateDateTime = (datetime?: string | null) => {
  if (!(datetime && moment(datetime).isValid())) return null;
  return datetime;
};

/**
 * Maps a list of orders to the keys needed for generating a shipment assembly
 */
export const mapOrdersToLoad = (orders: Array<PurchaseOrderResponse>, companyConfigId?: string, planName?: string) => {
  return {
    name: planName || '',
    company_id: companyConfigId || '',
    orders: orders.map((order) => {
      const totalOrderWeight = getTotalOrderWeightPounds(order.line_items || []);
      const lineItemsWithExternalId = order?.line_items?.map((lineItem: PurchaseOrderLineItemResponse) => {
        return {...lineItem, external_id: lineItem.id};
      });
      return {
        external_id: order.id || '',
        pickup_start_time: validateDateTime(order.planned_pickup_start_datetime),
        pickup_end_time: validateDateTime(order.planned_pickup_end_datetime),
        drop_off_start_time: validateDateTime(order.planned_delivery_start_datetime),
        drop_off_end_time: validateDateTime(order.planned_delivery_end_datetime),
        origin_address: {
          lat: order.origin_address?.latitude,
          long: order.origin_address?.longitude,
          external_id: order.origin_address?.id,
          ...order.origin_address
        },
        destination_address: {
          lat: order.destination_address?.latitude,
          long: order.destination_address?.longitude,
          external_id: order.destination_address?.id,
          ...order.destination_address
        },
        line_items: lineItemsWithExternalId,
        order_number: order.order_number,
        weight: totalOrderWeight,
        order_name: order.name,
        purchase_order_number: order.purchase_order_number,
        customer_name: order.customer_name,
        supplier_name: order.supplier_name,
        //you can't optimize orders assigned to shipment, but send just in case
        shipment_id: order.shipment?.id,
        origin_address_book_entry: order.origin_address_book_entry,
        origin_dock_external_id: order.origin_dock_external_id,
        destination_address_book_entry: order.destination_address_book_entry,
        destination_dock_external_id: order.destination_dock_external_id,
        custom_data: order.custom_data
      };
    })
  } as PlanSchema;
};

// Function to extract the external ID from a stop point or return null if it doesn't exist
const extractExternalIdFromStop = (stop: OrderStop | undefined): string | null => {
  return stop && stop.references
    ? stop.references.find(
        (ref) => ref.qualifier === 'ADDRESS_BOOK_ENTRY_ID' || ref.qualifier === 'ADDRESS_BOOK_ENTRY_REFERENCE_ID'
      )?.value || null
    : null;
};

// Function to calculate the total weight of a list of order items
const calculateTotalOrderWeight = (items: OrderItem[]): number => {
  return items.reduce((total, item) => {
    // Check if shipping_requirements is present and not null
    if (item?.shipping_requirements?.gross_weight?.value) {
      return total + parseFloat(item.shipping_requirements.gross_weight.value);
    }
    return total;
  }, 0);
};

export const mapV3OrdersToLoad = (orders: PurchaseOrder[], companyConfigId?: string, planName?: string) => {
  return {
    name: planName || '',
    company_id: companyConfigId || '',
    orders: orders.map((order) => {
      return {
        external_id: order.id || '',
        pickup_start_time: validateDateTime(order?.ship_from?.shipping_requirements?.plan_window?.start),
        pickup_end_time: validateDateTime(order?.ship_from?.shipping_requirements?.plan_window?.end),
        drop_off_start_time: validateDateTime(order?.ship_to.shipping_requirements?.plan_window?.start),
        drop_off_end_time: validateDateTime(order?.ship_to.shipping_requirements?.plan_window?.end),
        origin_address: {
          ...order.ship_from,
          lat: order.ship_from?.geolocation?.latitude,
          long: order.ship_from?.geolocation?.longitude,
          external_id: extractExternalIdFromStop(order.ship_from),
          state_province: order?.ship_from?.region,
          address_1: order.ship_from?.line_1,
          address_2: order.ship_from?.line_2,
          city: order.ship_from?.locality
        },
        destination_address: {
          ...order.ship_to,
          lat: order?.ship_to.geolocation?.latitude,
          long: order?.ship_to.geolocation?.longitude,
          external_id: extractExternalIdFromStop(order.ship_to),
          state_province: order?.ship_to?.region,
          address_1: order.ship_to?.line_1,
          address_2: order.ship_to?.line_2,
          city: order.ship_to?.locality
        },
        line_items: order.items ? mapOrderItemsToLineItemsSchema(order.items) : undefined,
        order_number: order.order_number,
        weight: calculateTotalOrderWeight(order.items || []),
        order_name: order.name,
        purchase_order_number: order.order_number,
        supplier_name: order.supplier?.name,
        custom_data: order.custom_data,
        resource_type: OrderResourceType.PurchaseOrder,
        origin_address_book_entry: order.ship_from?.references
          ? getOrderStopReferencesObject(order.ship_from.references).addressBookEntryId
          : undefined,
        destination_address_book_entry: order.ship_to?.references
          ? getOrderStopReferencesObject(order.ship_to?.references).addressBookEntryId
          : undefined,
        tags: order.tags
      };
    })
  };
};

export type StopWithFlattenedAddress = StopSchema & {address: BaseAddressSchema | Record<string, never>};

export const getShipmentAssemblyStopTypeLabel = (stopType: string) =>
  stopType === StopType.Pickup ? 'Pickup' : 'Dropoff';

export const getLoadStopAddress = (stop: StopSchema) => {
  if (!stop.orders?.length || stop.orders.length === 0) {
    return {...stop, address: {}};
  }
  if (stop.stop_type === StopType.Pickup) {
    return {...stop, address: stop?.orders[0].origin_address} as StopWithFlattenedAddress;
  }
  if (stop.stop_type === StopType.Drop) {
    return {...stop, address: stop.orders[0].destination_address} as StopWithFlattenedAddress;
  }
  return {...stop, address: {}};
};

export const getPlanLineInfoByLoad = (load: LoadSchema) => {
  if (!load || !load.stops) {
    return [];
  }
  return load.stops?.map((stop) => {
    const loadStopAddress = getLoadStopAddress(stop);
    return {
      mode: load.mode || '',
      coords: [loadStopAddress.address?.long || 0, loadStopAddress.address?.lat || 0]
    };
  });
};

/**
 * Returns a query string that redirects to the new shipment/ new quote page for
 * a shipment created from purchase orders
 */
export const getCreatedShipmentQueryString = (
  creationType: string,
  shipmentId: string,
  selectedPurchaseOrders = [],
  mode?: ShipmentMode
) =>
  //Should redirect to the new quote page if type === Quote or if mode === Parcel,
  //since for Parcels we should redirect only to the new Quote page
  creationType === BulkOrderActions.Quote || mode?.id === 6
    ? `/shipments/${shipmentId}/edit?showSuccess=true&ordersConsolidated=${selectedPurchaseOrders.length}`
    : `/new-shipment/${shipmentId}?showSuccess=true&ordersConsolidated=${selectedPurchaseOrders.length}`;

export const ORDER_DROP_OFF_BEFORE_PICKUP_ERROR_MESSAGE = 'Order drop off cannot occur before order pickup.';
export const ORDER_PICKUP_AFTER_DROP_OFF_ERROR_MESSAGE = 'Order pickup cannot occur after order drop off.';

/**
 * Return the appropriate order sequence error message
 */
const getOrderSequenceErrorMessage = (selectedStopSource: StopSchema, selectedStopDestination: StopSchema) => {
  if (selectedStopSource?.stop_type === StopType.Drop && selectedStopDestination?.stop_type === StopType.Pickup) {
    return ORDER_DROP_OFF_BEFORE_PICKUP_ERROR_MESSAGE;
  }
  if (selectedStopSource?.stop_type === StopType.Pickup && selectedStopDestination?.stop_type === StopType.Drop) {
    return ORDER_PICKUP_AFTER_DROP_OFF_ERROR_MESSAGE;
  }
};
/**
 * Tests if  dragging a stop results in order drop off
 * occurring before order pickup, or order pickup occurring after drop off
 */
export const testOrderPickupSequence = (
  selectedStopSourceIndex: number,
  selectedStopDestinationIndex: number,
  stops: Array<StopSchema>
) => {
  const selectedStopSource = get(stops, selectedStopSourceIndex);
  const selectedStopDestination = get(stops, selectedStopDestinationIndex);
  let orderSequenceErrors: {isValidOrderSequence: boolean; errorMessage: string | null} = {
    isValidOrderSequence: true,
    errorMessage: null
  };
  let stopsDraggedOver: StopSchema[] = [];
  //the user is moving the stop up in stop chronology
  if (selectedStopSourceIndex > selectedStopDestinationIndex) {
    stopsDraggedOver = stops.filter(
      (stop, stopIndex) => stopIndex >= selectedStopDestinationIndex && stopIndex < selectedStopSourceIndex
    );
  }
  //the user is moving the stop down in stop chronology
  if (selectedStopDestinationIndex > selectedStopSourceIndex) {
    stopsDraggedOver = stops.filter(
      (stop, stopIndex) => stopIndex > selectedStopSourceIndex && stopIndex <= selectedStopDestinationIndex
    );
  }
  const draggedStopOrderIds = selectedStopSource?.orders?.map((order) => order?.external_id);
  //check if the dragged stop contains orders from the stops being dragged over
  stopsDraggedOver.forEach((stop) => {
    if (stop.orders?.some((order) => draggedStopOrderIds?.includes(order?.external_id))) {
      orderSequenceErrors = {
        isValidOrderSequence: false,
        errorMessage: getOrderSequenceErrorMessage(selectedStopSource, selectedStopDestination) || null
      };
    }
  });
  return orderSequenceErrors;
};
/**
 * Returns a string with humanized trip duration display
 */

export const formatTripDuration = (tripDurationMinutes: number) =>
  isNumber(tripDurationMinutes) && duration(tripDurationMinutes, 'minutes').humanize();

/**
 * Returns stops array with stop window casted to date
 */

export const castStopWindowToDate = (stops: Array<StopSchema>) =>
  stops.map((stop) => {
    return {
      ...stop,
      planned_start_time: getDateObjectFromDateString(stop?.planned_start_time || ''),
      planned_end_time: getDateObjectFromDateString(stop?.planned_end_time || '')
    };
  });
/**
 * Returns a callback URL if load is successfully converted to a shipment
 */

export const getLoadCreationSuccessCallbackUrl = (
  planId: PlanSchema['id'] = '',
  loadNumber: LoadSchema['load_number'] = 0
) => `/load-optimizations/plan/${planId}?loadNumCreated=${loadNumber + 1}`;
/**
 * Returns an object with the load shipment field values
 */
export const getLoadShipmentFieldPayload = (values: LoadSchema = {}) => {
  return {
    equipment_type: get(values, 'equipment_type.machine_readable'),
    mode: get(values, 'mode.code') as LoadSchema['mode'],
    customer_id: get(values, 'customer_id.company.id')
  };
};

// Event Heirarchy: DELETE_ORDER, MODEL_RERUN, SUGGEST_TO_RERUN
// reduce actions to their most important to the user per order
// returns an object who's keys are order ids and values are action type (EX: {random_order_id: 'DELETE_ORDER'})
export const reducedOrdersWithSingleAction = (orders: OptimizeOrderSchema[]) =>
  orders.reduce((actions: {[x: string]: ActionType}, order) => {
    const containsActionType = (action: ActionType) =>
      order.updated_events?.some((event) => event.action_to_resolve === action);
    const orderId = order.id || '';
    if (containsActionType(ActionType.DeleteOrder)) {
      return {...actions, [orderId]: ActionType.DeleteOrder};
    }
    if (containsActionType(ActionType.ModelRerun)) {
      return {...actions, [orderId]: ActionType.ModelRerun};
    }
    if (containsActionType(ActionType.SuggestToRerun)) {
      return {...actions, [orderId]: ActionType.SuggestToRerun};
    }
    return actions;
  }, {});

//returns an object who's keys are order ids and values are a list of action types (EX: {random_order_id: ['DELETE_ORDER', 'SUGGEST_TO_RERUN']})
export const reducedOrdersWithActionsList = (orders: OptimizeOrderSchema[]) =>
  orders.reduce((actions: {[x: string]: (ActionType | undefined)[] | undefined}, order) => {
    const orderId = order.id || '';
    return {...actions, [orderId]: order.updated_events?.map((event) => event.action_to_resolve)};
  }, {});

export const getMapModeColor = (mode: LoadSchemaModeEnum) =>
  mode === LoadSchemaModeEnum.Ftl ? MAP_FTL_COLOR : mode === LoadSchemaModeEnum.Ltl ? MAP_LTL_COLOR : '';
