import {Tag} from '@shipwell/corrogo-sdk';
import {
  OptimizationCategoryConstraintOperator,
  OptimizationCategoryConstraintSchema
} from '@shipwell/shipment-assembly-sdk';
import {LogicalConstraintValue} from '../../logicalConstraint/logicalConstraintValue';
import {RulesEnum} from '../../logicalConstraint/ruleSelect/rulesEnum';
import {TagSelectItem} from '../../logicalConstraint/tagSelect/selectItem';

export function getUniqueSlugs(constraints?: OptimizationCategoryConstraintSchema[]) {
  const slugHashMap: Record<string, boolean> = {};
  constraints?.forEach((item) => {
    item.left_operand?.forEach((slug) => (slugHashMap[slug] = true));
    item.right_operand?.forEach((slug) => (slugHashMap[slug] = true));
  });
  return Object.keys(slugHashMap).map((key) => key.toLowerCase());
}

export function getLogicalConstraintValue(constraints: OptimizationCategoryConstraintSchema[], cachedTags: Tag[]) {
  //@ts-expect-error the SDK seems to be incorrectly typed; passing an empty array returns an error, but null saves correctly.
  return constraints.map<LogicalConstraintValue>((item) => {
    const isUnary = [
      OptimizationCategoryConstraintOperator.Individually,
      OptimizationCategoryConstraintOperator.Ignore
    ].includes(item.operator);

    return {
      firstValue: isUnary ? null : getValueForSlugs(item.left_operand, cachedTags),
      rule: getRuleFromOperator(item.operator),
      secondValue: getValueForSlugs(item.right_operand, cachedTags)
    };
  });
}

function getRuleFromOperator(operator: OptimizationCategoryConstraintOperator) {
  switch (operator) {
    case OptimizationCategoryConstraintOperator.Ignore:
      return RulesEnum.ignore;
    case OptimizationCategoryConstraintOperator.Individually:
      return RulesEnum.individually;
    case OptimizationCategoryConstraintOperator.NotWith:
      return RulesEnum.notWith;
    // Note we do NOT want ‘ONLY WITH’ option displayed as of now because we are still working on the backend modeling to support this so not going to release the “ONLY WITH” option initially
    // case OptimizationCategoryConstraintOperator.OnlyWith:
    //   return RulesEnum.onlyWith;
  }
}

function getValueForSlugs(slugs: string[] | null, cachedTags: Tag[]) {
  if (!slugs) return [];
  return slugs.map<TagSelectItem>((slug) => {
    const tag = cachedTags.find((ct) => ct.slug.toLowerCase() === slug.toLowerCase());
    if (!tag) throw new Error(`${slug} is not cached`);
    return {
      color: tag.color,
      label: tag.name,
      value: tag.id,
      slug: tag.slug
    };
  });
}
