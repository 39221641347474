import {useCallback} from 'react';
import {AsyncSelect, Tag} from '@shipwell/shipwell-ui';
import {TagSelectItem} from './selectItem';
import {listTags} from 'App/api/corrogo/tags';

export interface TagSelectProps {
  disabled?: boolean;
  value: TagSelectItem[];
  error?: string;
  onChange: (value: TagSelectItem[]) => void;
}

export function TagSelect(props: TagSelectProps) {
  const loadOptions = useCallback(async (inputStr: string) => {
    const result = await listTags({q: inputStr});
    return result.data.data.map<TagSelectItem>((tag) => ({
      label: tag.name,
      value: tag.id,
      slug: tag.slug,
      color: tag.color
    }));
  }, []);

  return (
    <AsyncSelect
      label={'Select Tag(s)'}
      isMulti
      isClearable
      defaultOptions
      error={props.error}
      disable={props.disabled}
      value={props.value}
      loadOptions={loadOptions}
      components={{
        MultiValue: (mvProps) => (
          <div className="mb-1 mr-1">
            <Tag
              size="s"
              maxWidth={150}
              label={mvProps.data.label}
              color={mvProps.data.color}
              onClick={() => mvProps.selectOption(mvProps.data)}
              onDelete={() => {
                const values = mvProps.selectProps.value as TagSelectItem[];
                const filtered = values.filter((val) => val.value !== mvProps.data.value);
                mvProps.setValue(filtered, 'deselect-option');
              }}
            />
          </div>
        ),
        Option: (optionProps) => {
          const {data, selectOption} = optionProps;
          return (
            <div className="mb-1">
              <Tag size="m" fullWidth label={data.label} color={data.color} onClick={() => selectOption(data)} />
            </div>
          );
        }
      }}
      onChange={(newValue) => props.onChange([...newValue])}
    />
  );
}
