import {useFormik} from 'formik';
import {RulesEnum} from '../logicalConstraint/ruleSelect/rulesEnum';
import {LogicalConstraintValue} from '../logicalConstraint/logicalConstraintValue';
import {ConstraintsFormValues} from './constraintsFormValue';
import {validationSchema} from './validationSchema';

export interface UseConstraintsFormProps {
  values?: ConstraintsFormValues;
}

export function useConstraintsForm(props: UseConstraintsFormProps) {
  const form = useFormik<ConstraintsFormValues>({
    initialValues: props.values || {
      constraints: []
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: () => {
      console.log('unused');
    }
  });

  function addConstraint() {
    const newItem: LogicalConstraintValue = {
      firstValue: [],
      rule: RulesEnum.notWith,
      secondValue: []
    };

    void form.setFieldValue(`constraints[${form.values.constraints.length}]`, newItem);
  }

  function removeConstraintAt(idx: number) {
    const newConstraints = [...form.values.constraints];
    newConstraints.splice(idx, 1);
    void form.setFieldValue('constraints', newConstraints);
  }

  function updateConstraintAt(idx: number, data: LogicalConstraintValue) {
    void form.setFieldValue(`constraints[${idx}]`, data);
  }

  return {
    values: form.values,
    errors: form.errors,
    dirty: form.dirty,
    resetForm: form.resetForm,
    validateForm: form.validateForm,
    addConstraint,
    updateConstraintAt,
    removeConstraintAt
  };
}
