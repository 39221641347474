/* global $, google */
import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, change, FormSection} from 'redux-form';
import {Form, Button, Row, Col} from 'react-bootstrap';
import {Tooltip, SvgIcon, Flex} from '@shipwell/shipwell-ui';
import renderMultiSelectList from 'App/formComponents/renderMultiSelectList';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderPhoneNumberField from 'App/formComponents/renderPhoneNumberField';
import renderField from 'App/formComponents/renderField';
import AddressSearchField from 'App/formComponents/fields/addressSearch';
import renderErrorField from 'App/formComponents/renderErrorField';
import {isBillToFormEmpty} from 'App/utils/globals';
import './_mode.scss';
import * as actions from 'App/actions/shipments';
import withFlags from 'App/utils/withFlags';
var Scroll = require('react-scroll');
var Element = Scroll.Element;

@connect((state) => {}, actions)
class BillTo extends Component {
  handleClearFields(e) {
    e.preventDefault();

    if (this.props.onClear) {
      this.props.onClear();
    }
  }

  render() {
    const {onClear, formValues, showHeader, billToNotRequired} = this.props;
    let hasRequiredFields = false;

    if (billToNotRequired) {
      hasRequiredFields = false;
    }

    if (formValues && formValues.values && formValues.values.bill_to_override) {
      hasRequiredFields = !isBillToFormEmpty(formValues.values.bill_to_override);
    }

    return (
      <div id="billTo">
        {showHeader ? (
          <h2 className="border-bottom bill-to-header">
            Bill-To
            {onClear && (
              <a href="#" onClick={this.handleClearFields.bind(this)}>
                clear
              </a>
            )}
          </h2>
        ) : null}
        <div className="formstyle-light">
          <Element name={`position-bill_to_override`} />
          <Element name={`position-bill_to_override.company_name`} />
          <Element name={`position-bill_to_override.company_address`} />
          <Element name={`position-bill_to_override.contact_email`} />
          <Element name={`position-bill_to_override.contact_phone`} />

          <div className="quote-flow__billTo">
            <FormSection name="bill_to_override">
              <Flex alignItems="center">
                <div className="grow">
                  <Field label="Billing ID" component={renderField} name="billing_id" placeholder="Billing ID" />
                </div>
                <Tooltip
                  tooltipContent={
                    'Billing ID is an optional field that can be used to help carriers identify which office to bill.'
                  }
                  placement="auto"
                  trigger="hover"
                  wrapperClassname="tooltipWrapper"
                  tooltipClassname="tooltipContent"
                >
                  <SvgIcon className="ml-2" name="InfoOutlined" color="$sw-icon" />
                </Tooltip>
              </Flex>
              <Field
                component={renderDropDownSelector}
                data={[
                  {id: 'PREPAID', name: 'Prepaid'},
                  {id: 'COLLECT', name: 'Collect'},
                  {id: '3RD_PARTY', name: '3rd Party'}
                ]}
                name="direction"
                label="Select Bill-to Option"
                req={hasRequiredFields}
                placeholder="Select"
              />
              <Field
                label="Company"
                name="company_name"
                req={hasRequiredFields}
                component={renderField}
                placeholder="Bill-to company"
              />
              <Field
                label="Address"
                name="company_address"
                req={hasRequiredFields}
                component={AddressSearchField}
                placeholder="Bill-to address"
              />
              <Field
                label="Contact Email"
                name="contact_email"
                req={hasRequiredFields}
                component={renderField}
                placeholder="Bill-to email"
              />
              <Field
                label="Contact Phone"
                component={renderPhoneNumberField}
                name="contact_phone"
                req={hasRequiredFields}
                placeholder="Bill-to phone"
              />
            </FormSection>
          </div>
        </div>
      </div>
    );
  }
}

export default withFlags('billToNotRequired')(BillTo);

BillTo.defaultProps = {
  showHeader: true
};
