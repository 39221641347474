import {RegularSelect} from '@shipwell/shipwell-ui';
import {useMemo} from 'react';
import {RulesEnum} from './rulesEnum';

export interface RuleSelectProps {
  value: RulesEnum;
  error?: string;
  onChange: (value: RulesEnum) => void;
}

export function RuleSelect(props: RuleSelectProps) {
  const options = useMemo(() => {
    return [
      {label: 'Not With', value: RulesEnum.notWith},
      {label: 'Individually', value: RulesEnum.individually},
      {label: 'Ignore', value: RulesEnum.ignore}
      // Note we do NOT want ‘ONLY WITH’ option displayed as of now because we are still working on the backend modeling to support this so not going to release the “ONLY WITH” option initially
      // {label: 'Only With', value: RulesEnum.onlyWith}
    ];
  }, []);

  const selectedValue = options.find((opt) => opt.value === props.value);

  return (
    <RegularSelect
      label={'rule'}
      value={selectedValue}
      error={props.error}
      options={options}
      onChange={(newValue) => {
        if (newValue) props.onChange(newValue.value);
      }}
    />
  );
}
