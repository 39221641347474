import {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import {Field, FieldArray} from 'formik';
import PropTypes from 'prop-types';
import {FormikSelect, FormikTextInput, DeprecatedButton, SvgIcon, Rule} from '@shipwell/shipwell-ui';
import {fetchAccessorialCodes} from 'App/actions/_shipments';
import {PROPTYPE_ACCESSORIAL} from 'App/utils/propTypeConstants';
import {preferredCurrencyOptionsShipwellUiSelect} from 'App/containers/Book/FormSections/Financials/components/utils';

const AccessorialRateFields = ({values, dispatch, accessorials}) => {
  useEffect(() => {
    if (accessorials.length < 1) {
      dispatch(fetchAccessorialCodes());
    }
  }, [dispatch, accessorials.length]);

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <Field
          name="currency"
          component={FormikSelect}
          label="Currency"
          options={preferredCurrencyOptionsShipwellUiSelect}
          simpleValue
          required
          clearable={false}
        />
      </div>
      <FieldArray
        name="calculation_rows"
        render={(arrayHelpers) => (
          <>
            <div className="mb-2 text-sw-form-helper-text">Add default rates for your accessorials</div>
            {values.calculation_rows.map((calculationRow, i) => (
              // It is not ideal to use an array index as a key,
              // however the data we are trying to map is dynamic,
              // with no static properties (like ID) to use as an alternative.
              <Fragment key={i}>
                {values.calculation_rows.length > 1 && i >= 1 && (
                  <div className="mb-4">
                    <Rule />
                  </div>
                )}
                <div className="mb-4 grid gap-4">
                  <div className="flex gap-4">
                    <div className="w-full">
                      <Field
                        name={`calculation_rows[${i}].accessorial`}
                        component={FormikSelect}
                        label="Accessorial"
                        getOptionLabel={({description}) => description}
                        options={accessorials}
                        simpleOptionValue="code"
                        simpleValue
                        required
                      />
                    </div>
                    {values.calculation_rows.length > 1 && (
                      <DeprecatedButton
                        variant="icon"
                        icon={<SvgIcon name="TrashOutlined" />}
                        onClick={() => {
                          arrayHelpers.remove(i);
                        }}
                      />
                    )}
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <Field name={`calculation_rows[${i}].rate`} component={FormikTextInput} label="Rate" required />
                    <Field
                      name={`calculation_rows[${i}].calculation_description`}
                      component={FormikTextInput}
                      label="Description"
                      required
                    />
                  </div>
                </div>
              </Fragment>
            ))}
            <DeprecatedButton
              icon={<SvgIcon name="AddCircleOutlined" />}
              variant="tertiary"
              onClick={() => {
                arrayHelpers.push({});
              }}
            >
              Accessorial Rate
            </DeprecatedButton>
          </>
        )}
      />
    </div>
  );
};

AccessorialRateFields.propTypes = {
  dispatch: PropTypes.func,
  values: PropTypes.shape({
    calculation_rows: PropTypes.arrayOf({
      accessorial: PropTypes.string,
      rate: PropTypes.string,
      calculation_description: PropTypes.string
    })
  }),
  accessorials: PropTypes.arrayOf(PROPTYPE_ACCESSORIAL)
};

export default connect((state) => ({
  accessorials: state.shipments.accessorialCodes
}))(AccessorialRateFields);
