import PropTypes from 'prop-types';
import {useState} from 'react';
import {object} from 'yup';
import {Formik, Form} from 'formik';
import {DeprecatedButton, Card, SvgIcon, StepIndicator} from '@shipwell/shipwell-ui';
import {postAccessorialChargeTablePromise, updateAccessorialChargeTablePromise} from 'App/api/shipment';
import AccessorialCriteriaFields from 'App/formComponents/formSections/accessorialCriteria';
import {AccessorialSettingsFields} from 'App/formComponents/formSections/accessorialSettingsFields';
import AccessorialRateFields from 'App/formComponents/formSections/accessorialRates';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {
  defaultAccessorialRatesFormValues,
  accessorialCriteriaValidationSchemaFields,
  accessorialRatesValidationSchemaFields
} from 'App/containers/userCompany/accessorialRates/validation';
import {formatAddresses} from 'App/containers/workflows/utils';
import 'App/containers/userCompany/accessorialRates/styles.scss';
import {useGetInternationalPreferences} from 'App/containers/internationalization/hooks/useGetInternationalPreferences';

const steps = [
  {
    stepTitle: 'Define Criteria',
    cardTitle: 'Accessorial Criteria',
    modalPrimaryActionName: 'Create and Continue',
    explainer:
      'Accessorial rate tables allow you to automatically set your accessorial rates. Set your policy criteria to define when to use these accessorial rates.',
    validationSchema: object().shape(accessorialCriteriaValidationSchemaFields)
  },
  {
    stepTitle: 'Define Rates',
    cardTitle: 'Accessorial Rates',
    modalPrimaryActionName: 'Save',
    validationSchema: object().shape(accessorialRatesValidationSchemaFields)
  }
];

const CompanyAccessorialRatesTablesCreate = ({onSubmit, onCancel, setError}) => {
  const [selectedAccessorialRatesTable, setSelectedAccessorialRatesTable] = useState(null);
  const [stepIndex, setStepIndex] = useState(0);
  const [isCreatingTable, setIsCreatingTable] = useState(false);

  const internationalPreferences = useGetInternationalPreferences();
  const preferredCurrency = internationalPreferences?.data?.currency;

  const handleSubmit = async (values) => {
    setIsCreatingTable(true);

    try {
      const {currency} = values;
      const calculationRows = [...(values.calculation_rows || [])].map((row) => ({
        ...row,
        rate_currency: currency || preferredCurrency
      }));
      const payload = {
        ...values,
        locations: formatAddresses(values.locations),
        carrier_relationships: values.carrier_relationships
          ? values.carrier_relationships.map((carrier) => carrier.id)
          : [],
        customers: values.customers ? values.customers.map((customer) => customer?.id) : [],
        calculation_rows: selectedAccessorialRatesTable?.id ? calculationRows : []
      };

      let response;
      if (selectedAccessorialRatesTable?.id) {
        response = await updateAccessorialChargeTablePromise(selectedAccessorialRatesTable.id, payload);
      } else {
        response = await postAccessorialChargeTablePromise(payload);
      }
      if (stepIndex < steps.length - 1) {
        setSelectedAccessorialRatesTable(response.body);
        setStepIndex(stepIndex + 1);
      } else {
        onSubmit(response);
      }
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }

    setIsCreatingTable(false);
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const initialValues = {...defaultAccessorialRatesFormValues, currency: preferredCurrency};

  return (
    <>
      <div className="mb-4">
        <StepIndicator steps={steps.map((step) => step.stepTitle)} alternativeLabel activeStep={stepIndex} />
      </div>
      {steps[stepIndex].explainer && (
        <div className="companyAccessorialRates-modal-explainer">{steps[stepIndex].explainer}</div>
      )}
      <Formik
        enableReinitialize
        validationSchema={steps[stepIndex].validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({isValid, values, setFieldValue}) => (
          <Form noValidate>
            {stepIndex === 0 ? (
              <>
                <Card title="Accessorial Criteria" className="mb-4">
                  <AccessorialCriteriaFields values={values} setFieldValue={setFieldValue} />
                </Card>
                <Card title="Settings">
                  <AccessorialSettingsFields />
                </Card>
              </>
            ) : stepIndex === 1 ? (
              <Card title={steps[stepIndex].cardTitle}>
                <AccessorialRateFields values={values} setFieldValue={setFieldValue} />
              </Card>
            ) : null}
            <ModalFormFooter
              isSubmitting={isCreatingTable}
              isValid={isValid}
              onCancel={handleCancel}
              primaryActionName={steps[stepIndex].modalPrimaryActionName}
              options={
                stepIndex > 0 && (
                  <DeprecatedButton
                    variant="tertiary"
                    onClick={() => setStepIndex(stepIndex - 1)}
                    className="fuelSurcharge__back"
                  >
                    <SvgIcon name="ArrowBack" />
                    Back
                  </DeprecatedButton>
                )
              }
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

CompanyAccessorialRatesTablesCreate.defaultProps = {
  isCreatingTable: false,
  onCancel: () => {},
  onSubmit: () => {}
};

CompanyAccessorialRatesTablesCreate.propTypes = {
  isCreatingTable: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  setError: PropTypes.func
};

export default CompanyAccessorialRatesTablesCreate;
