import {AppointmentEntry} from 'App/data-hooks/appointments/types';
import {AppointmentDateInfo} from './AppointmentDateInfo';
import {AppointmentStatusInfo} from './AppointmentStatusInfo';

interface Option {
  label: string;
  value: string;
  appointment: AppointmentEntry;
}

export const FormatOptionLabel = (option: Option, {context}: {context: 'menu' | 'value'}) => {
  const {appointment} = option;

  if (context === 'menu') {
    return (
      <div className="flex items-start justify-between grey-color py-2">
        <AppointmentStatusInfo
          status={appointment.status}
          shipmentReferenceId={appointment.scheduledResourceReferenceId}
          companyName={appointment.carrierName}
        />
        <AppointmentDateInfo start={appointment.start} end={appointment.end} status={appointment.status} />
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <span className="font-bold">{appointment.scheduledResourceReferenceId || ''}</span>
    </div>
  );
};
