import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import last from 'lodash/last';
import {
  OceanTracking,
  RailTracking,
  ContainerShipmentItem,
  ProductShipmentItem,
  ContainerType,
  ContainerOrderItem,
  ProductOrderItem,
  DrayageBooking,
  ContainerLegItem,
  ProductLegItem,
  OceanTrackingOrderService,
  RailTrackingOrderService,
  Stop,
  RunningStop,
  UpdateContact,
  CreateContact,
  ContainerLengthFT,
  ContainerWidthFT,
  ContainerHeightFT,
  LocationType,
  Stage,
  LegStage,
  LegStageStageTypeEnum,
  ServiceStage,
  ServiceStageStageTypeEnum,
  ShipmentService,
  LegacyShipmentStageStageTypeEnum,
  RailTrackingReference,
  OceanTrackingReference,
  DrayageReference,
  DrayageReferenceType
} from '@shipwell/corrogo-sdk';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import {Shipment, Hazmat} from '@shipwell/backend-core-singlerequestparam-sdk';
import {formatDateToMMDDYY} from 'App/utils/dateTimeGlobals';
import {formatDateTime} from 'App/utils/dateTimeGlobalsTyped';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {
  weightLimits,
  weightUnits,
  trackingServiceTypeToPortType,
  contacts as contactsConstants,
  stopReferencesQualifier
} from 'App/containers/shipments/utils/constants';
import {
  ContainerPickupStopType,
  ContainerReturnStopType,
  DeliveryStopType,
  PickupStopType,
  LoadLocationStopType
} from 'App/containers/shipments/components/StopsFields/StopTypeContactsFields';
import {StopTypeId, StopTypeType} from 'App/containers/shipments/components/StopsFields/constants';

declare type TrackingReferenceType = (RailTrackingReference | OceanTracking) & {
  qualifier: string;
  value: string;
};

type StopTitle = 'Pickup' | 'Load Location' | 'Delivery' | 'Container Pickup' | 'Container Return' | 'Stop';

export function isService(stage: Stage | undefined): stage is ServiceStage {
  return stage?.stage_type === ServiceStageStageTypeEnum.Service;
}

export function isOceanOrRailTracking(service: ShipmentService): service is OceanTracking | RailTracking {
  return (
    (service as OceanTracking)?.steamship_line !== undefined || (service as RailTracking)?.rail_company !== undefined
  );
}

export function isOceanTracking(
  service:
    | OceanTracking
    | RailTracking
    | DrayageBooking
    | OceanTrackingOrderService
    | RailTrackingOrderService
    | undefined
): service is OceanTracking | OceanTrackingOrderService {
  return (service as OceanTracking)?.steamship_line !== undefined;
}

export function isContainerItem(
  item:
    | ProductShipmentItem
    | ContainerShipmentItem
    | ProductOrderItem
    | ContainerOrderItem
    | ProductLegItem
    | ContainerLegItem
): item is ContainerShipmentItem {
  return has(item, 'identification_number');
}

export function isContainerLegItem(
  legItem: ContainerLegItem | ProductLegItem | undefined
): legItem is ContainerLegItem {
  return has(legItem, 'identification_number');
}

export function isContainerOrderItem(item: ContainerOrderItem | ProductOrderItem): item is ContainerOrderItem {
  return 'identification_number' in item;
}

export function checkIsOverweight(weight?: string, unit?: string) {
  if (!weight || !unit) {
    return false;
  }
  const weightUnit = unit === weightUnits.POUNDS ? weightUnits.POUNDS : weightUnits.KILOGRAMS;
  return parseFloat(weight) >= weightLimits[weightUnit];
}

const getContainerTypeForDisplay = (existingContainerInfo: {
  container_type?: string;
  length?: string;
  width?: string;
  height?: string;
  dimension_unit?: string;
}) => {
  if (existingContainerInfo.container_type === ContainerType.Oversize) {
    return containerDisplayTypes.OVERSIZE;
  }
  const containerInfoKeys = Object.keys(containerInfoMap);
  return containerInfoKeys.find((containerInfoKey) =>
    isEqual(containerInfoMap[containerInfoKey], existingContainerInfo)
  );
};

export function transformContainerInfoToDisplayValues(
  containers: (ContainerShipmentItem | ContainerOrderItem | ContainerLegItem)[]
) {
  return containers.map((container) => {
    const existingContainerInfo = {
      container_type: container.container_type,
      length: container.dimensions?.length,
      width: container.dimensions?.width,
      height: container.dimensions?.height,
      dimension_unit: container.dimensions?.unit
    };
    const displayContainerType = getContainerTypeForDisplay(existingContainerInfo);

    const isContainer = isContainerItem(container);
    const rawContainerId = isContainer ? container.identification_number : '';
    const containerId = rawContainerId || '--';
    const sealNumber = isContainer ? container.seals?.[0]?.value || '--' : '--';
    const quantity = new Intl.NumberFormat().format(container.quantity);
    const packagingType = startCaseToLower(container.packaging_type);
    const rawContainerType = isContainer ? container.container_type : '';
    const containerType = displayContainerType ?? '--';
    const isOversize = rawContainerType === ContainerType.Oversize;
    const dimensions = container.dimensions || null;
    const temperature = container.temperature;
    const temperatureRange =
      (rawContainerType === ContainerType.Refrigerated || rawContainerType === ContainerType.RefrigeratedTank) &&
      temperature?.maximum &&
      temperature?.minimum &&
      temperature?.unit
        ? ` (${temperature.minimum}${temperature.unit} - ${temperature.maximum}${temperature.unit})`
        : '';
    const dimensionsDisplay =
      isOversize && dimensions
        ? ` (${dimensions.length as string}x${dimensions.width as string}x${
            dimensions.height as string
          }${dimensions.unit.toLowerCase()})`
        : null;
    const description = container.description && container.description.length > 0 ? container.description : '--';
    const childUnits = container.child_units || [];
    const numOfItems = childUnits.length;
    const returnBy = isContainer ? (container.return_by ? formatDateToMMDDYY(container.return_by) : '--') : '--';
    const key = container.id;
    const weightUnit = container.gross_weight.unit;
    const weight = container.gross_weight.value;
    const formattedWeight = `${new Intl.NumberFormat().format(parseFloat(weight))}`;
    const formattedWeightWithUnit = `${formattedWeight}${weightUnit}`.toLowerCase();
    const overweight = checkIsOverweight(weight, weightUnit);
    const hazmat = container.hazmat;
    const hazmatDisplayName = hazmat?.proper_shipping_name;

    const restrictionList = [];
    if (hazmat) {
      restrictionList.push('Hazmat');
    }
    if (overweight) {
      restrictionList.push('Overweight');
    }
    const restrictions = restrictionList.join(', ') || '--';

    return {
      rawContainerId,
      containerId,
      sealNumber,
      quantity,
      packagingType,
      containerType,
      dimensionsDisplay,
      description,
      returnBy,
      hazmat,
      hazmatDisplayName,
      key,
      childUnits,
      numOfItems,
      restrictions,
      temperatureRange,
      weightInfo: {formattedWeight, formattedWeightWithUnit, weightUnit, overweight}
    };
  });
}

export function transformDrayageLegItemsToFormValues(
  containers: (ContainerShipmentItem | ContainerOrderItem | ContainerLegItem)[],
  getHazmatOptionFromId: (identificationNumber: string) => Hazmat | undefined
) {
  if (containers.length <= 0) {
    return {};
  }
  return {
    containers: containers.map((container) => {
      const existingContainerInfo = {
        container_type: container.container_type,
        length: container.dimensions?.length,
        width: container.dimensions?.width,
        height: container.dimensions?.height,
        dimension_unit: container.dimensions?.unit
      };
      const containerType = getContainerTypeForDisplay(existingContainerInfo);

      return {
        container_number: container.identification_number,
        seal_number: container.seals?.find((seal) => seal.qualifier === 'default-seal')?.value,
        quantity: container.quantity,
        commodity: container.description,
        child_units: container.child_units ?? [],
        package_type: container.packaging_type,
        gross_weight: container.gross_weight?.value,
        gross_weight_unit: container.gross_weight?.unit,
        container_type: containerType,
        length: container.dimensions?.length,
        width: container.dimensions?.width,
        height: container.dimensions?.height,
        dimension_unit: container.dimensions?.unit,
        min_temp: container.temperature?.minimum,
        max_temp: container.temperature?.maximum,
        container_return_date: container.return_by,
        hazmat: !isEmpty(container.hazmat),
        hazmat_code: container.hazmat ? getHazmatOptionFromId(container.hazmat.identification_number) : null,
        id: container.id,
        key: container.id
      };
    })
  };
}

export const generateContainerLabel = (
  index: number,
  containerIdentificationNumber?: string,
  containerSealNumber?: string
) =>
  `Container #${containerIdentificationNumber ? containerIdentificationNumber : index + 1}${
    containerSealNumber ? ` - Seal #${containerSealNumber}` : ''
  }`;

export const transformServiceToDisplayValues = (
  service?: OceanTracking | RailTracking | OceanTrackingOrderService | RailTrackingOrderService
) => {
  if (!service) {
    return {};
  }

  const isOceanTrackingService = isOceanTracking(service);
  const serviceType = service?.service_type;
  const portType = startCaseToLower(serviceType ? trackingServiceTypeToPortType[serviceType] : '--');

  const steamshipLine = isOceanTrackingService ? service.steamship_line || '--' : service.rail_company || '--';
  const identificationCodes = service?.identification_codes || undefined;
  const estimatedArrival = service?.planned_arrival;
  const formattedEstimatedArrival = estimatedArrival ? formatDateTime(estimatedArrival) : '--';
  const actualArrival = service?.actual_arrival;
  const formattedActualArrival = actualArrival ? formatDateTime(actualArrival) : '--';
  const thirdPartyContact = service?.contacts?.find((contact) => contact.contact_type === contactsConstants.OTHER);

  const vesselName = isOceanTrackingService && service.vessel_name ? service.vessel_name : '--';
  const voyageNumber = isOceanTrackingService && service.voyage_number ? service.voyage_number : '--';

  return {
    serviceType,
    portType,
    steamshipLine,
    formattedEstimatedArrival,
    formattedActualArrival,
    vesselName,
    voyageNumber,
    thirdPartyContact,
    identificationCodes
  };
};

export function isLegStage(stage: Stage): stage is LegStage {
  return stage.stage_type === LegStageStageTypeEnum.Leg;
}

export function isRunningStop(stop?: RunningStop | Stop): stop is RunningStop {
  return !!stop && 'actual_arrival_time' in stop;
}

function isUpdateContact(contact?: UpdateContact | CreateContact): contact is UpdateContact {
  return !!contact && 'id' in contact;
}

export function transformStopToDisplayValues(stop: Stop | RunningStop | undefined, title: StopTitle) {
  const companyName = stop?.location.company_name || '--';

  const address = stop?.location.address;
  const addressLineOne = address?.line_1 || '';
  const addressLineTwo = address?.line_2 || '';
  const city = address?.locality || '';
  const region = address?.region || '';
  const zip = address?.postal_code || '';
  const addressSingleLine =
    addressLineOne && city && region && zip
      ? `${addressLineOne}, ${addressLineTwo ?? ''}${city}, ${region} ${zip}`
      : '--';

  const contacts = stop?.contacts?.filter(isUpdateContact);

  const instructions = stop?.instructions || '--';

  const pickupQualifier = stop?.references?.find(
    (pickupQualifier) => pickupQualifier.qualifier === stopReferencesQualifier.PICKUP_NUMBER
  );

  const appointmentTime = isRunningStop(stop) ? stop?.appointment?.begin : null;
  const formattedAppointmentTime = appointmentTime ? formatDateTime(appointmentTime) : '--';
  const pickupDate = isRunningStop(stop) ? stop?.plan_window?.ready : null;
  const formattedPickupDate = pickupDate ? formatDateTime(pickupDate) : '--';
  const actualArrivalTime = isRunningStop(stop) ? stop?.actual_arrival_time : null;
  const formattedActualArrivalTime = actualArrivalTime ? formatDateTime(actualArrivalTime) : '--';
  const completedAtTime = isRunningStop(stop) ? stop?.actual_departure_time : null;
  const formattedCompletedAtTime = completedAtTime ? formatDateTime(completedAtTime) : '--';

  const executionStatus = isRunningStop(stop) ? startCaseToLower(stop.execution_status) : '';

  return {
    title,
    companyName,
    addressLineOne,
    addressLineTwo,
    city,
    region,
    zip,
    addressSingleLine,
    contacts,
    instructions,
    pickupQualifier,
    formattedAppointmentTime,
    formattedPickupDate,
    formattedActualArrivalTime,
    formattedCompletedAtTime,
    executionStatus,
    sequenceNumber: stop?.sequence_number || -1,
    locationType: stop?.location.location_type,
    laneDescription: city && region ? `${city}, ${region}` : null
  };
}

export type StopDisplay = ReturnType<typeof transformStopToDisplayValues>;

export function getStopInfo(stop: Stop | RunningStop, stopTitle: StopTitle) {
  return {
    stop,
    stopDisplayValues: transformStopToDisplayValues(stop, stopTitle),
    stopFormValues: transformStopToFormValues(stop)
  };
}

export function getPickupAndDeliveryStops(stops: (Stop | RunningStop)[], isImport: boolean) {
  return stops.filter((stop) => {
    const stopClassifications = getStopClassifications(stop, isImport);
    return !stopClassifications.isContainerPickup && !stopClassifications.isContainerReturn;
  });
}

function getStopClassifications<TStopType extends Stop = Stop>(stop: TStopType, isImport: boolean) {
  const isContainerPickup = stop.location.location_type === LocationType.ContainerYard && !isImport;
  const isContainerReturn = stop.location.location_type === LocationType.ContainerYard && isImport;
  const isLoad = stop.is_load;
  const isDelivery = stop.is_unload;
  return {isContainerPickup, isContainerReturn, isLoad, isDelivery};
}

export function transformStopsToDisplayValues(stops: Stop[] | undefined, isImport: boolean) {
  return stops?.map((stop) => {
    const {isContainerPickup, isContainerReturn, isLoad, isDelivery} = getStopClassifications(stop, isImport);
    const title = isContainerPickup
      ? 'Container Pickup'
      : isContainerReturn
      ? 'Container Return'
      : isLoad
      ? isImport
        ? 'Pickup'
        : 'Load Location'
      : isDelivery
      ? 'Delivery'
      : 'Stop';
    return transformStopToDisplayValues(stop, title);
  });
}

export function transformStopToFormValues(stop: Stop | RunningStop | undefined) {
  const {instructions} = stop || {};
  const {
    line_1: line1,
    line_2: line2,
    line_3: line3,
    locality,
    region,
    country,
    postal_code: postalCode
  } = stop?.location.address || {};

  const companyName = stop?.location.company_name;

  const pickupQualifier = stop?.references?.find(
    (pickupQualifier) => pickupQualifier.qualifier === stopReferencesQualifier.PICKUP_NUMBER
  );

  const appointmentTime = isRunningStop(stop) ? stop?.appointment?.begin : null;
  const pickupDate = isRunningStop(stop) ? stop?.plan_window?.ready : null;
  const actualArrivalTime = isRunningStop(stop) ? stop?.actual_arrival_time : null;
  const completedAtTime = isRunningStop(stop) ? stop?.actual_departure_time : null;

  const stopFormValues = {
    company_name: companyName || '',
    address_1: line1,
    address_2: `${line2 || ''} ${line3 || ''}`.trim(),
    city: locality,
    state_province: region,
    country: country || '',
    postal_code: postalCode,
    formatted_address: [
      line1 || '',
      line2 || '',
      line3 || '',
      locality || '',
      region || '',
      country || '',
      postalCode || ''
    ]
      .join(' ')
      .trim(),
    instructions,
    point_of_contacts: stop?.contacts
      ?.map((contact) => {
        if (!isUpdateContact(contact)) {
          return;
        }
        return {
          first_name: first((contact?.person_name || '').split(' ', 2)),
          last_name: last((contact?.person_name || '').split(' ', 2)),
          phone_number: contact.phone,
          email: contact.email,
          id: contact.id
        };
      })
      .filter((contact) => !!contact),
    pickup_number: pickupQualifier,
    sequenceNumber: stop?.sequence_number,
    appointment_begin: appointmentTime,
    plan_window_ready: pickupDate,
    actual_arrival_time: actualArrivalTime,
    actual_departure_time: completedAtTime
  };
  return stopFormValues;
}

export function transformStopsToFormValues(stops: Stop[] | undefined) {
  return stops?.map((stop) => {
    return transformStopToFormValues(stop);
  });
}

function getStopType<TStopType extends Stop = Stop>(stop: TStopType, isImport: boolean) {
  const {isContainerPickup, isContainerReturn, isLoad, isDelivery} = getStopClassifications<TStopType>(stop, isImport);
  const stopType = isContainerPickup
    ? ContainerPickupStopType
    : isContainerReturn
    ? ContainerReturnStopType
    : isLoad
    ? isImport
      ? PickupStopType
      : LoadLocationStopType
    : isDelivery
    ? DeliveryStopType
    : null;
  return stopType;
}

export function createSequenceNumberStopTypeAccessor<TStopType extends Stop = Stop>(
  stops: TStopType[] | undefined,
  isImport: boolean
) {
  const sequenceNumberStopTypeMap = stops?.reduce<{[key: number]: StopTypeType}>((stopTypeMap, stop) => {
    const stopType = getStopType(stop, isImport);
    if (stopType === null) {
      return stopTypeMap;
    }
    return {...stopTypeMap, [stop.sequence_number]: stopType};
  }, {});

  return function getStopTypeForSequenceNumber(sequenceNumber: number) {
    return sequenceNumberStopTypeMap ? sequenceNumberStopTypeMap[sequenceNumber] : PickupStopType;
  };
}

export function createStopByTypeAccessor<TStopType extends Stop = Stop>(
  stops: TStopType[] | undefined,
  isImport: boolean
) {
  const stopTypeMap = stops?.reduce<Partial<{[key in StopTypeId]: TStopType}>>((stopTypeMap, stop) => {
    const stopType = getStopType(stop, isImport);
    if (stopType === null) {
      return stopTypeMap;
    }
    return {...stopTypeMap, [stopType.id]: stop};
  }, {});

  return function getStopByType(stopType: StopTypeId): TStopType | undefined {
    return stopTypeMap ? stopTypeMap[stopType] : undefined;
  };
}

export const containerDisplayTypes = {
  TWENTY_FOOT_STANDARD: "20' Standard",
  FORTY_FOOT_STANDARD: "40' Standard",
  FORTY_FIVE_FOOT_STANDARD: "45' Standard",
  TWENTY_FOOT_OPEN_TOP: "20' Open Top",
  FORTY_FOOT_OPEN_TOP: "40' Open Top",
  FORTY_FIVE_FOOT_OPEN_TOP: "45' Open Top",
  TWENTY_FOOT_FLAT_RACK: "20' Flat Rack",
  FORTY_FOOT_FLAT_RACK: "40' Flat Rack",
  FORTY_FIVE_FOOT_FLAT_RACK: "45' Flat Rack",
  TWENTY_FOOT_HIGH_CUBE: "20' High Cube",
  FORTY_FOOT_HIGH_CUBE: "40' High Cube",
  FORTY_FIVE_FOOT_HIGH_CUBE: "45' High Cube",
  TWENTY_FOOT_REEFER: "20' Reefer",
  FORTY_FOOT_REEFER: "40' Reefer",
  FORTY_FIVE_FOOT_REEFER: "45' Reefer",
  TWENTY_FOOT_ISO_TANK: "20' ISO Tank",
  TWENTY_FOOT_REEFER_TANK: "20' Reefer Tank",
  FIFTY_THREE_FOOT_CONTAINER: "53' Container",
  OVERSIZE: 'Oversize'
};

export const containerTypeOptions = Object.keys(containerDisplayTypes).map((key) => ({
  label: containerDisplayTypes[key as keyof typeof containerDisplayTypes],
  value: containerDisplayTypes[key as keyof typeof containerDisplayTypes]
}));

export const containerInfoMap = {
  [containerDisplayTypes.TWENTY_FOOT_STANDARD]: {
    container_type: ContainerType.GeneralPurpose,
    length: ContainerLengthFT._20,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.FORTY_FOOT_STANDARD]: {
    container_type: ContainerType.GeneralPurpose,
    length: ContainerLengthFT._40,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.FORTY_FIVE_FOOT_STANDARD]: {
    container_type: ContainerType.GeneralPurpose,
    length: ContainerLengthFT._45,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.TWENTY_FOOT_OPEN_TOP]: {
    container_type: ContainerType.OpenTop,
    length: ContainerLengthFT._20,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.FORTY_FOOT_OPEN_TOP]: {
    container_type: ContainerType.OpenTop,
    length: ContainerLengthFT._40,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.FORTY_FIVE_FOOT_OPEN_TOP]: {
    container_type: ContainerType.OpenTop,
    length: ContainerLengthFT._45,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.TWENTY_FOOT_FLAT_RACK]: {
    container_type: ContainerType.FlatRack,
    length: ContainerLengthFT._20,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.FORTY_FOOT_FLAT_RACK]: {
    container_type: ContainerType.FlatRack,
    length: ContainerLengthFT._40,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.FORTY_FIVE_FOOT_FLAT_RACK]: {
    container_type: ContainerType.FlatRack,
    length: ContainerLengthFT._45,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.TWENTY_FOOT_HIGH_CUBE]: {
    container_type: ContainerType.GeneralPurpose,
    length: ContainerLengthFT._20,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._95,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.FORTY_FOOT_HIGH_CUBE]: {
    container_type: ContainerType.GeneralPurpose,
    length: ContainerLengthFT._40,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._95,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.FORTY_FIVE_FOOT_HIGH_CUBE]: {
    container_type: ContainerType.GeneralPurpose,
    length: ContainerLengthFT._45,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._95,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.TWENTY_FOOT_REEFER]: {
    container_type: ContainerType.Refrigerated,
    length: ContainerLengthFT._20,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.FORTY_FOOT_REEFER]: {
    container_type: ContainerType.Refrigerated,
    length: ContainerLengthFT._40,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.FORTY_FIVE_FOOT_REEFER]: {
    container_type: ContainerType.Refrigerated,
    length: ContainerLengthFT._45,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.TWENTY_FOOT_ISO_TANK]: {
    container_type: ContainerType.Tank,
    length: ContainerLengthFT._20,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.TWENTY_FOOT_REEFER_TANK]: {
    container_type: ContainerType.RefrigeratedTank,
    length: ContainerLengthFT._20,
    width: ContainerWidthFT._8,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.FIFTY_THREE_FOOT_CONTAINER]: {
    container_type: ContainerType.GeneralPurpose,
    length: ContainerLengthFT._20,
    width: ContainerWidthFT._85,
    height: ContainerHeightFT._85,
    dimension_unit: 'FT'
  },
  [containerDisplayTypes.OVERSIZE]: {
    container_type: ContainerType.Oversize,
    dimension_unit: 'FT'
  }
};

export function getStageByResourceId(resourceId: string, stages: Stage[] | undefined) {
  return stages?.find((stage) =>
    stage.stage_type === LegStageStageTypeEnum.Leg
      ? resourceId === stage.leg.id
      : stage.stage_type === ServiceStageStageTypeEnum.Service
      ? resourceId === stage.service.id
      : stage.stage_type === LegacyShipmentStageStageTypeEnum.LegacyShipment
      ? stage.legacy_shipment_id === resourceId
      : null
  );
}

/**
 * Searches for the identification code set as the SCAC and transforms it
 * into a Drayage Reference type.
 *
 * If no SCAC can be found on the identification codes or the value is `null` then the method returns `null`.
 */
export function transformIdentificationCodeScac(
  identificationCodes?: RailTrackingReference[] | OceanTrackingReference[]
): DrayageReference | null {
  if (isNil(identificationCodes)) {
    return null;
  }

  const scacIentificationCode = (identificationCodes as TrackingReferenceType[]).find(
    (code) => code.qualifier.toLowerCase() === 'scac'
  );
  if (isNil(scacIentificationCode) || isNil(scacIentificationCode.value)) {
    return null;
  }

  return {
    qualifier: DrayageReferenceType.Scac,
    value: scacIentificationCode.value
  };
}

// Add to this only as needed. Some redux form fields differ from the SDK Shipment type
export type NewShipmentFormValues = Partial<
  Pick<Shipment, 'stops' | 'line_items' | 'total_weight_override'> & {
    mode?: number | null;
    equipment_type?: number | null;
    carrier?: {
      value: string;
      label: string;
      disabled: boolean;
    } | null;
  }
>;
