import {useQuery} from '@tanstack/react-query';
import {PaginationState, SortingState} from '@tanstack/react-table';
import {SearchFormValues} from '../../searchForm/searchFormValues';
import {TAGS_SEARCH_KEY} from './constants';
import {listTags} from 'App/api/corrogo/tags';

export interface UseTagsServiceProps {
  filters?: SearchFormValues;
  pagination: PaginationState;
  sorting: SortingState;
}

export function useTagsService(props: UseTagsServiceProps) {
  const searchQuery = useQuery({
    queryKey: [TAGS_SEARCH_KEY, props.filters, props.pagination],
    queryFn: async () => {
      return listTags({
        q: props.filters?.term,
        page: props.pagination.pageIndex + 1,
        limit: props.pagination.pageSize
      });
    }
  });

  return {
    data: searchQuery.data?.data,
    isLoading: searchQuery.isFetching
  };
}
