import {ChangeEvent, Dispatch, ReactNode, SetStateAction, useMemo, useRef, useState} from 'react';
import lodash from 'lodash';
import {
  Card,
  FormikTextInput,
  FormikSelect,
  FormikCheckbox,
  CollapsibleCardContent,
  Tooltip,
  SvgIcon,
  FormikRadioGroup,
  Checkbox,
  Popover
} from '@shipwell/shipwell-ui';
import {
  CompanySchema,
  DistanceUnitType,
  PackageType,
  SpeedUnitType,
  WeightUnitType,
  VolumeUnitType
} from '@shipwell/shipment-assembly-sdk';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {Form, Formik, Field, FormikHelpers} from 'formik';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {compose} from 'recompose';
import {RouteComponentProps} from 'react-router';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {CompanyLoadOptimizationFormValues} from './types';
import {companyLoadOptimizationValidationSchema} from './validation';
import {
  defaultConfigValues,
  LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_MESSAGE,
  LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_TITLE,
  LOAD_OPTIMIZATION_CONFIG_SAVE_SUCCESS_MESSAGE,
  LOAD_OPTIMIZATION_CONFIG_SAVE_SUCCESS_TITLE
} from './constants';
import {OperatingDaysInputs} from './OperatingDaysInputs';
import {LogicalConstraintsCard, LogicalConstraintsCardRef} from './logicalConstraintsCard/logicalConstraintsCard';
import {getPointerFieldErrorKeyValues} from 'App/containers/loadOptimization/utils/parseFieldErrors';
import {FlexBox, Box} from 'App/components/Box';
import {freightClasses} from 'App/utils/globals';
import PageHeader from 'App/common/pageHeader';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {createHoursList} from 'App/utils/getTimeOptions';
import {getCompanySettingsById, createCompanySettings} from 'App/api/loadOptimization';
import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import ShipwellLoader from 'App/common/shipwellLoader';
import {COMPANY_LOAD_OPTIMIZATION_CONFIGURATIONS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import FetchingIndicator from 'App/components/FetchingIndicator';
import {useUpdateCompanySettings} from 'App/api/loadOptimization/hooks/useUpdateCompanySettings';
import {getV3ApiErrorDetail} from 'App/api/typedUtils';
import {ShipmentAssemblyBaseErrorResponse} from 'App/containers/loadOptimization/types';
import {useCustomFields} from 'App/data-hooks';

const weightUnitOptions = [
  {
    label: 'Pounds',
    value: WeightUnitType.Lb
  },
  {
    label: 'Kilograms',
    value: WeightUnitType.Kg
  }
];
const volumeUnitOptions = [
  {
    label: 'Cubic feet',
    value: VolumeUnitType.Ft
  },
  {
    label: 'Cubic meters',
    value: VolumeUnitType.M
  }
];
const speedUnitOptions = [
  {
    label: 'MPH',
    value: SpeedUnitType.Mph
  },
  {
    label: 'KPH',
    value: SpeedUnitType.Kph
  }
];
const distanceUnitOptions = [
  {
    label: 'Miles',
    value: DistanceUnitType.Miles
  },
  {
    label: 'Kilometers',
    value: DistanceUnitType.Kilometers
  }
];
interface CompanyLoadOptimizationConfigProps
  extends RouteComponentProps<{configId: string}, {configId: string}>,
    WithStatusToastProps {}

const CompanyLoadOptimizationConfig = ({
  routeParams,
  router,
  setSuccess,
  setError
}: CompanyLoadOptimizationConfigProps) => {
  const {configId = ''} = routeParams || {};

  const logicalConstraintsCardRef = useRef<LogicalConstraintsCardRef>(null);
  const [logicalConstraintsCardDirty, setLogicalConstraintsCardDirty] = useState(false);

  const [showCustomPalletOverride, setShowCustomPalletOverride] = useState<boolean>();
  const {customFields} = useCustomFields(CustomFieldEntityTypesEnum.PurchaseOrder);

  const flags = useFlags();

  const companyQuery = useQuery(
    [COMPANY_LOAD_OPTIMIZATION_CONFIGURATIONS_QUERY_KEY, configId],
    async () => {
      const response = await getCompanySettingsById(configId);
      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!configId
    }
  );

  const {updateCompanySettingsMutation, invalidateConfigListQuery} = useUpdateCompanySettings({
    onSuccess: () => {
      setSuccess(LOAD_OPTIMIZATION_CONFIG_SAVE_SUCCESS_TITLE, LOAD_OPTIMIZATION_CONFIG_SAVE_SUCCESS_MESSAGE);
    },
    onError: (error) =>
      setError(
        LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_TITLE,
        getV3ApiErrorDetail(error, LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_MESSAGE)
      )
  });
  const createCompanySettingsMutation = useMutation<
    Awaited<ReturnType<typeof createCompanySettings>>,
    ShipmentAssemblyBaseErrorResponse,
    CompanySchema
  >(
    (newCompanyDetails: CompanySchema) => createCompanySettings(newCompanyDetails),

    {
      onSuccess: () => {
        setSuccess(LOAD_OPTIMIZATION_CONFIG_SAVE_SUCCESS_TITLE, LOAD_OPTIMIZATION_CONFIG_SAVE_SUCCESS_MESSAGE);
      },
      onError: (error) =>
        setError(
          LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_TITLE,
          getV3ApiErrorDetail(error, LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_MESSAGE)
        )
    }
  );
  const queryClient = useQueryClient();

  async function handleSubmit(
    values: CompanyLoadOptimizationFormValues,
    helpers: FormikHelpers<CompanyLoadOptimizationFormValues>
  ) {
    const isLogicalConstraintsValid = await logicalConstraintsCardRef.current?.validate();
    if (!isLogicalConstraintsValid) return;

    const sanitizedObject = lodash.omitBy(values, (value) => value === '' || value === null || value === undefined);

    if (values.id) {
      await updateCompanySettingsMutation.mutateAsync(sanitizedObject, {
        onError: (error: ShipmentAssemblyBaseErrorResponse) => {
          helpers.setErrors(getPointerFieldErrorKeyValues(error));
          helpers.setSubmitting(false);
        }
      });
      await logicalConstraintsCardRef.current?.save(configId);
      await invalidateConfigListQuery();
    } else {
      const createResponse = await createCompanySettingsMutation.mutateAsync(sanitizedObject, {
        onError: (error: ShipmentAssemblyBaseErrorResponse) => {
          helpers.setErrors(getPointerFieldErrorKeyValues(error));
          helpers.setSubmitting(false);
        }
      });

      const newCompanyId = createResponse.data?.id || '';
      await logicalConstraintsCardRef.current?.save(newCompanyId);
      await queryClient.invalidateQueries([COMPANY_LOAD_OPTIMIZATION_CONFIGURATIONS_QUERY_KEY, configId]);
      //push to the id of the new company settings record, in case user wants to make additional changes
      router.push(`/company/defaults/load-optimization-configurations/${newCompanyId}`);
    }
  }

  const initialValues = useMemo<CompanyLoadOptimizationFormValues>(() => {
    return configId && companyQuery?.data ? companyQuery.data : defaultConfigValues;
  }, [companyQuery.data, configId]);

  if (companyQuery.isInitialLoading) {
    return (
      <div className="inset-0 flex w-full items-center justify-center">
        <ShipwellLoader loading />
      </div>
    );
  }
  if (companyQuery.isFetching) return <FetchingIndicator />;

  const DefaultConfigTooltip = () => (
    <div className="max-w-[325px] overflow-auto">
      You may only have one active default configuration at a time. The default will be selected automatically when
      optimizing orders but you will be able to select any other configuration you&#39;ve created.
    </div>
  );
  const OptimizationMethodTooltip = () => (
    <div className="max-w-[325px] overflow-auto">
      By default, loads are optimized based on cost. You can change this to optimize loads based on distance. This can
      be more efficient when travelling short distances/local routes, where baseline costs aren&#39;t a factor.
    </div>
  );

  const ResponsiveThreeColumnGridWrapper = ({children}: {children: ReactNode}) => (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-3">{children}</div>
  );

  const ResponsiveTwoColumnGridWrapper = ({children}: {children: ReactNode}) => (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">{children}</div>
  );

  return (
    <div className="w-full">
      <Formik
        validationSchema={companyLoadOptimizationValidationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({dirty, resetForm, initialValues, values, setFieldValue}) => {
          const handleShowCustomPallet = ({target: {checked}}: ChangeEvent<HTMLInputElement>) => {
            setShowCustomPalletOverride(checked);
            if (!checked) {
              setFieldValue('custom_reference_pallet_override', null);
            }
          };

          const showPalletOverride = showCustomPalletOverride || !!values.custom_reference_pallet_override;

          const isAnyDirty = dirty || logicalConstraintsCardDirty;

          return (
            <Form>
              <PageHeader
                title={configId ? values.setting_name || '' : 'New Configuration Settings'}
                backRoute="/company/defaults/load-optimization-configurations"
              />
              <div className="m-auto max-w-5xl pb-6">
                <Box pad="l">
                  <Card title="Configuration Settings" draggableProvided={null}>
                    <div className="ml-2">
                      These settings will be used when orders are being optimized into load plans.
                    </div>
                    <FlexBox pad="s" direction="col" gap="m">
                      <ResponsiveThreeColumnGridWrapper>
                        <Field name="setting_name" label="Configuration Name" component={FormikTextInput} required />
                        <div className="flex items-center gap-1">
                          <Field
                            name="is_default"
                            label="Default Configuration"
                            component={FormikCheckbox}
                            //Disable user from unsetting default configuration in config detail
                            disabled={Boolean(configId && companyQuery.data?.is_default)}
                          />
                          <Tooltip trigger="hover" tooltipContent={<DefaultConfigTooltip />} placement="top">
                            <SvgIcon height="18" width="18" name="InfoOutlined" />
                          </Tooltip>
                        </div>
                      </ResponsiveThreeColumnGridWrapper>

                      <Card title="Truck Settings" isCollapsible draggableProvided={null}>
                        <CollapsibleCardContent>
                          <ResponsiveThreeColumnGridWrapper>
                            <Field
                              name="average_truck_speed"
                              label="Average truck speed"
                              component={FormikTextInput}
                              required
                            />
                            <Field
                              name="speed_unit"
                              label="Unit"
                              options={speedUnitOptions}
                              component={FormikSelect}
                              simpleValue
                              clearable={false}
                              required
                            />
                          </ResponsiveThreeColumnGridWrapper>
                          <div className="text-md mb-1 mt-4 font-bold">Truckload Capacity</div>
                          <ResponsiveThreeColumnGridWrapper>
                            <Field
                              name="minimum_truck_weight"
                              label="Minimum weight"
                              component={FormikTextInput}
                              required
                            />
                            <Field
                              name="maximum_truck_weight"
                              label="Maximum weight"
                              component={FormikTextInput}
                              required
                            />
                            <Field
                              name="weight_unit"
                              label="Unit"
                              options={weightUnitOptions}
                              component={FormikSelect}
                              simpleValue
                              clearable={false}
                              required
                            />
                            <Field name="minimum_truck_volume" label="Minimum volume" component={FormikTextInput} />
                            <Field name="maximum_truck_volume" label="Maximum volume" component={FormikTextInput} />
                            <Field
                              name="volume_unit"
                              label="Volume unit"
                              options={volumeUnitOptions}
                              component={FormikSelect}
                              simpleValue
                            />
                            <Field
                              name="minimum_package_count"
                              label="Minimum pallets"
                              component={FormikTextInput}
                              required
                            />
                            <Field
                              name="maximum_package_count"
                              label="Maximum pallets"
                              component={FormikTextInput}
                              required
                            />
                            <Field
                              name="package_type"
                              label="Package Type"
                              component={FormikSelect}
                              options={[{id: PackageType.Plt, label: 'Pallet'}]}
                              simpleValue
                              disabled
                            />
                            <>
                              <Checkbox
                                label="Custom Pallet Override"
                                onChange={handleShowCustomPallet}
                                checked={showPalletOverride}
                              />
                              {showPalletOverride ? (
                                <Field
                                  name="custom_reference_pallet_override"
                                  label="Custom Pallet Override"
                                  component={FormikSelect}
                                  options={customFields}
                                  clearable={false}
                                  simpleValue
                                  required
                                />
                              ) : null}
                            </>
                          </ResponsiveThreeColumnGridWrapper>
                        </CollapsibleCardContent>
                      </Card>

                      <Card isCollapsible draggableProvided={null} title="Stop Settings">
                        <CollapsibleCardContent>
                          <FlexBox direction="col" gap="s">
                            <ResponsiveThreeColumnGridWrapper>
                              <Field
                                name="minimum_stop_time_seconds"
                                label="Minimum stop time"
                                options={createHoursList(100, 1)}
                                component={FormikSelect}
                                simpleValue
                                required
                                clearable={false}
                              />
                              <Field name="maximum_stops" label="Maximum stops" component={FormikTextInput} required />
                              <Field
                                name="stop_charges_dollars"
                                label="Stop charge ($)"
                                component={FormikTextInput}
                                required
                              />
                            </ResponsiveThreeColumnGridWrapper>
                            <Field
                              name="frozen_as_last_stop"
                              label="Place orders with Min Temp of 0 degrees F or less as the last stop on the load"
                              component={FormikCheckbox}
                            />
                          </FlexBox>
                        </CollapsibleCardContent>
                      </Card>

                      <Card isCollapsible draggableProvided={null} title="Facility Operating Hours">
                        <CollapsibleCardContent>
                          <OperatingDaysInputs initialValues={initialValues} />
                        </CollapsibleCardContent>
                      </Card>
                      <Card title="Baseline Rating Settings" isCollapsible draggableProvided={null}>
                        <CollapsibleCardContent>
                          <FlexBox direction="col" gap="s">
                            <ResponsiveTwoColumnGridWrapper>
                              <Field
                                simpleValue
                                name="ltl_freight_class"
                                label="LTL Freight class"
                                // convert ids to string so simpleValue works with string return from backend
                                options={freightClasses.map((freightClass) => ({
                                  id: freightClass.name,
                                  label: freightClass.name
                                }))}
                                component={FormikSelect}
                                required
                                clearable={false}
                              />
                              <Field
                                required
                                name="ltl_discount_percentage"
                                label="LTL Discount (%)"
                                component={FormikTextInput}
                              />
                              <FlexBox items="center" gap="s">
                                <Field
                                  className="w-full"
                                  name="ltl_rate_adjustment_percentage"
                                  label="LTL Rate Adjustment (%)"
                                  component={FormikTextInput}
                                />
                                <Popover
                                  trigger={({
                                    setTriggerElement,
                                    setIsOpen
                                  }: {
                                    setIsOpen: Dispatch<SetStateAction<boolean>>;
                                    setTriggerElement: Dispatch<SetStateAction<HTMLElement | null>>;
                                  }) => (
                                    <div
                                      ref={setTriggerElement}
                                      onMouseEnter={() => setIsOpen(true)}
                                      onMouseLeave={() => setIsOpen(false)}
                                    >
                                      <SvgIcon name="InfoOutlined" />
                                    </div>
                                  )}
                                  theme="dark"
                                  showArrow
                                  placement="top"
                                >
                                  <p className="m-0 w-60 p-4">
                                    Adjust the calculated baseline LTL rate by this percent. The rate can be between
                                    -99% and 500%
                                  </p>
                                </Popover>
                              </FlexBox>
                              <FlexBox items="center" gap="s">
                                <Field
                                  className="w-full"
                                  name="ftl_rate_adjustment_percentage"
                                  label="FTL Rate Adjustment (%)"
                                  component={FormikTextInput}
                                />
                                <Popover
                                  trigger={({
                                    setTriggerElement,
                                    setIsOpen
                                  }: {
                                    setIsOpen: Dispatch<SetStateAction<boolean>>;
                                    setTriggerElement: Dispatch<SetStateAction<HTMLElement | null>>;
                                  }) => (
                                    <div
                                      ref={setTriggerElement}
                                      onMouseEnter={() => setIsOpen(true)}
                                      onMouseLeave={() => setIsOpen(false)}
                                    >
                                      <SvgIcon name="InfoOutlined" />
                                    </div>
                                  )}
                                  theme="dark"
                                  showArrow
                                  placement="top"
                                >
                                  <p className="m-0 w-60 p-4">
                                    Adjust the calculated baseline FTL rate by this percent. The rate can be between
                                    -99% and 500%
                                  </p>
                                </Popover>
                              </FlexBox>
                            </ResponsiveTwoColumnGridWrapper>
                          </FlexBox>
                        </CollapsibleCardContent>
                      </Card>

                      {flags.airMode && (
                        <LogicalConstraintsCard
                          ref={logicalConstraintsCardRef}
                          companyId={configId}
                          onDirty={setLogicalConstraintsCardDirty}
                        />
                      )}

                      <Card title="Private Fleet Settings" isCollapsible draggableProvided={null}>
                        <CollapsibleCardContent>
                          <div className="flex flex-col gap-1">
                            <div className="flex items-center gap-1">
                              <div className="text-md font-bold">Optimization Method</div>
                              <Tooltip trigger="hover" tooltipContent={<OptimizationMethodTooltip />} placement="top">
                                <SvgIcon height="18" width="18" name="InfoOutlined" />
                              </Tooltip>
                            </div>
                            <Field
                              name="local_delivery"
                              options={[
                                {label: 'Optimize by cost', value: false},
                                {label: 'Optimize by distance', value: true}
                              ]}
                              isRequred={false}
                              component={FormikRadioGroup}
                            />
                          </div>
                          <div className="text-md mb-1 font-bold">Fleet Settings</div>
                          <div className="grid gap-4">
                            <ResponsiveThreeColumnGridWrapper>
                              <Field
                                name="maximum_distance_per_route"
                                label="Maximum distance/route"
                                component={FormikTextInput}
                              />
                              <Field
                                name="distance_unit"
                                label="Unit"
                                options={distanceUnitOptions}
                                component={FormikSelect}
                                simpleValue
                                clearable={false}
                              />
                            </ResponsiveThreeColumnGridWrapper>
                            <ResponsiveThreeColumnGridWrapper>
                              <Field
                                name="maximum_time_per_route_seconds"
                                label="Maximum driving hours/route"
                                options={createHoursList(200, 24)}
                                component={FormikSelect}
                                simpleValue
                              />
                              <Field
                                name="max_truck_count"
                                label="Maximum number of trucks"
                                component={FormikTextInput}
                              />
                            </ResponsiveThreeColumnGridWrapper>
                          </div>
                        </CollapsibleCardContent>
                      </Card>
                    </FlexBox>
                  </Card>
                </Box>
              </div>

              {isAnyDirty && (
                <FormFooter
                  primaryActionName="Save"
                  secondaryActionName="Cancel"
                  dirty={isAnyDirty}
                  onCancel={() => {
                    resetForm();
                    logicalConstraintsCardRef.current?.resetForm();
                  }}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default compose<CompanyLoadOptimizationConfigProps, Partial<CompanyLoadOptimizationConfigProps>>(
  withStatusToasts
)(CompanyLoadOptimizationConfig);
