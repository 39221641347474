import {AppointmentStatusEnum} from '@shipwell/tempus-sdk';
import {AppointmentStatusIcon} from './AppointmentStatusIcon';

interface AppointmentStatusInfoProps {
  status: AppointmentStatusEnum;
  shipmentReferenceId: string;
  companyName: string | undefined;
}

export const AppointmentStatusInfo = ({status, shipmentReferenceId, companyName}: AppointmentStatusInfoProps) => {
  return (
    <div className="flex items-center gap-2">
      <AppointmentStatusIcon status={status} />
      <div className="flex flex-col gap-1">
        <span className="font-bold text-[12px]">{shipmentReferenceId}</span>
        <span className="font-normal text-[12px]">{companyName}</span>
      </div>
    </div>
  );
};
