import {
  OptimizationCategoryConstraintOperator,
  OptimizationCategoryConstraintSchema
} from '@shipwell/shipment-assembly-sdk';
import {LogicalConstraintValue} from '../../logicalConstraint/logicalConstraintValue';
import {RulesEnum} from '../../logicalConstraint/ruleSelect/rulesEnum';

export function getOptimizationCategoriesFromLogicalConstraints(values: LogicalConstraintValue[]) {
  return values.map<OptimizationCategoryConstraintSchema>((value) => ({
    //@ts-expect-error the SDK seems to be incorrectly typed; passing an empty array returns an error, but null saves correctly.
    left_operand: value.firstValue && value.firstValue.length ? value.firstValue.map((tag) => tag.slug) : null,
    operator: getOperatorFromRule(value.rule),
    right_operand: value.secondValue.map((tag) => tag.slug)
  }));
}

function getOperatorFromRule(rule: RulesEnum) {
  switch (rule) {
    case RulesEnum.ignore:
      return OptimizationCategoryConstraintOperator.Ignore;
    case RulesEnum.individually:
      return OptimizationCategoryConstraintOperator.Individually;
    case RulesEnum.notWith:
      return OptimizationCategoryConstraintOperator.NotWith;
    // Note we do NOT want ‘ONLY WITH’ option displayed as of now because we are still working on the backend modeling to support this so not going to release the “ONLY WITH” option initially
    // case RulesEnum.onlyWith:
    //   return OptimizationCategoryConstraintOperator.OnlyWith;
  }
}
