import {SvgIcon} from '@shipwell/shipwell-ui';
import {ReactNode} from 'react';
import {hasDisabledSecondValue} from './helpers';
import {LogicalConstraintError, LogicalConstraintValue} from './logicalConstraintValue';
import {RuleSelect} from './ruleSelect/ruleSelect';
import {RulesEnum} from './ruleSelect/rulesEnum';
import {TagSelectItem} from './tagSelect/selectItem';
import {TagSelect} from './tagSelect/tagSelect';

export interface LogicalConstraintProps {
  number: ReactNode;
  value: LogicalConstraintValue;
  error?: LogicalConstraintError;
  onChange: (value: LogicalConstraintValue) => void;
  onRemove: () => void;
}

export function LogicalConstraint(props: LogicalConstraintProps) {
  const isFirstValueDisabled = hasDisabledSecondValue(props.value.rule);

  function handleFirstValueChange(fistValue: TagSelectItem[]) {
    props.onChange({
      ...props.value,
      firstValue: fistValue
    });
  }

  function handleRuleChange(rule: RulesEnum) {
    const firstValue = hasDisabledSecondValue(rule) ? null : props.value.firstValue;
    const secondValue = hasDisabledSecondValue(rule) ? [] : props.value.secondValue;
    props.onChange({
      ...props.value,
      rule,
      //@ts-expect-error the SDK seems to be incorrectly typed; passing an empty array returns an error, but null saves correctly.
      firstValue,
      secondValue
    });
  }

  function handleSecondValueChange(secondValue: TagSelectItem[]) {
    props.onChange({
      ...props.value,
      secondValue
    });
  }

  return (
    <div role="region" title="logical constraint" className="flex items-center gap-2">
      <div>
        <div className="flex size-5 items-center justify-center rounded-full border-2 border-sw-icon text-xs">
          <div>{props.number}</div>
        </div>
      </div>
      <div className={'grid flex-1 grid-cols-12 items-center gap-4'}>
        <div className="col-span-5">
          <TagSelect
            disabled={isFirstValueDisabled}
            value={props.value.firstValue}
            error={props.error?.firstValue}
            onChange={handleFirstValueChange}
          />
        </div>
        <div className="col-span-3">
          <RuleSelect value={props.value.rule} error={props.error?.rule} onChange={handleRuleChange} />
        </div>
        <div className="col-span-4">
          <TagSelect
            value={props.value.secondValue}
            error={props.error?.secondValue}
            onChange={handleSecondValueChange}
          />
        </div>
      </div>
      <div>
        <SvgIcon role="button" name="TrashOutlined" className="cursor-pointer" onClick={props.onRemove} />
      </div>
    </div>
  );
}
