import moment from 'moment';
import {
  Appointment,
  DeliveryTypeEnum,
  DockSchedulingEquipmentTypeEnum,
  Facility,
  FacilityAppointmentType,
  FacilityDock,
  DateTimeWithTimezone,
  LoadType,
  AppointmentStatusEnum,
  ScheduledResourceTypeEnum,
  LoadTypeDockRuleMatchResults,
  ReferenceQualifierEnum
} from '@shipwell/tempus-sdk';

import {DefaultAppointmentDurationMinutes, FacilityAppointmentResourceId} from './constants';
import {
  AppointmentAvailabilityWindow,
  AppointmentEntry,
  CalendarAppointmentEvent,
  ResourceStub,
  ViewMode
} from 'App/data-hooks/appointments/types';
import {cmp, compareHumanReadable} from 'App/utils/cmp';
import {objectKeys} from 'App/utils/betterTypedArrayMethods';
import {
  timeZoneAwareParse,
  withTimeOfDay,
  convertISO8601ToMinutes,
  parseYearMonthDate
} from 'App/utils/dateTimeGlobalsTyped';

/**
 * two use-cases for this function -
 * 1. when we pass a planned_date in the "YYYY-mm-dd" format (for unscheduled appts)
 * 2. when we need to get "today" but in the DateTimeWithTimezone type that comes from the SDK (in case both start/end AND planned_date are absent)
 */
export function formatToDateWithTimeZone(date: string | Date, preDeterminedTimezone?: string): DateTimeWithTimezone {
  if (typeof date === 'string') {
    date = parseYearMonthDate(date, preDeterminedTimezone);
  } else if (date instanceof Date) {
    // Adjust the date to midnight in the speficied timezone
    const momentDate = moment.tz(date, preDeterminedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
    momentDate.set('hour', 0);
    momentDate.set('minute', 0);
    momentDate.set('second', 0);
    momentDate.set('millisecond', 0);
    date = momentDate.toDate();
  }
  const timestamp = date.toISOString();
  const timezone = preDeterminedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    timestamp,
    timezone
  };
}

export function makeEntry(appointment: Appointment, timezone?: string): AppointmentEntry {
  let startDate: Date;
  let endDate: Date;
  let durationMs: number;

  if (!timezone) {
    timezone = moment.tz.guess();
  }

  if (appointment.start && appointment.end) {
    startDate = new Date(appointment.start.timestamp);
    endDate = new Date(appointment.end.timestamp);
    durationMs = +endDate - +startDate;
  } else if (
    appointment.start &&
    (appointment.is_all_day || appointment.appointment_type === FacilityAppointmentType.FirstComeFirstServe)
  ) {
    startDate = new Date(appointment.start.timestamp);
    durationMs = 86400 * 1000;
    endDate = new Date(+startDate + durationMs);
  } else if (appointment.start) {
    startDate = new Date(appointment.start.timestamp);
    // This case ought to be impossible, so we just do this sane default. - Joe 2023-09-06
    durationMs = DefaultAppointmentDurationMinutes * 60 * 1000;
    endDate = new Date(+startDate + durationMs);
  } else if (
    appointment.scheduled_resource_metadata?.resource_type === ScheduledResourceTypeEnum.Shipment &&
    appointment.scheduled_resource_metadata.planned_date
  ) {
    // This case handles Unscheduled appointments
    startDate = timeZoneAwareParse(appointment.scheduled_resource_metadata.planned_date, timezone);
    durationMs = 86400 * 1000;
    endDate = new Date(+startDate + durationMs);
  } else {
    // we know nothing :(
    startDate = withTimeOfDay(new Date(), '00:00:00', timezone);
    durationMs = 86400 * 1000;
    endDate = new Date(+startDate + durationMs);
  }
  const start = {timestamp: startDate.toISOString(), timezone};
  const end = {timestamp: endDate.toISOString(), timezone};

  return {
    id: appointment.id,
    facilityId: appointment.facility_id,
    description: appointment.description,
    dockId: appointment.dock_id ?? '',
    allDay: appointment.is_all_day ?? false,
    carrierName: appointment.carrier_name,
    carrierTenantId: appointment.carrier_tenant_id,
    scheduledResourceReferenceId: appointment.scheduled_resource_reference_id ?? '',
    durationMs,

    start,
    end,
    appointmentType: appointment.appointment_type ?? FacilityAppointmentType.ByAppointmentOnly,
    matchedLoadTypeId: appointment.matched_load_type_id,
    status: appointment.status,
    reason: appointment.reason,
    name: appointment.name,
    stopId: appointment.stop_id,
    scheduledResourceId: appointment.scheduled_resource_id,
    scheduledResourceType: appointment.scheduled_resource_type,
    scheduledResourceMetadata: appointment.scheduled_resource_metadata && {...appointment.scheduled_resource_metadata},
    deliveryType: appointment.delivery_type,
    checkedInAt: appointment.checked_in_at,
    checkedOutAt: appointment.checked_out_at,
    references:
      appointment.references?.map((ref) => ({
        qualifier: ref.qualifier,
        value: ref.value
      })) ?? [],
    rejectedAt: appointment.rejected_at,
    rejectedReasons: appointment.rejected_reasons
  };
}

export function unMakeEntry(entry: AppointmentEntry): Appointment {
  const appointment: Appointment = {
    id: entry.id,
    description: entry.description,
    facility_id: entry.facilityId,
    dock_id: entry.dockId || undefined,
    is_all_day: entry.allDay,
    carrier_name: entry.carrierName,
    carrier_tenant_id: entry.carrierTenantId,
    scheduled_resource_reference_id: entry.scheduledResourceReferenceId,
    start: entry.start,
    end: entry.end,
    appointment_type: entry.appointmentType,
    matched_load_type_id: entry.matchedLoadTypeId,
    status: entry.status,
    reason: entry.reason,
    name: entry.name,
    stop_id: entry.stopId,
    scheduled_resource_id: entry.scheduledResourceId,
    scheduled_resource_type: entry.scheduledResourceType,
    scheduled_resource_metadata: entry.scheduledResourceMetadata && {...entry.scheduledResourceMetadata},
    delivery_type: entry.deliveryType,
    checked_out_at: entry.checkedOutAt,
    checked_in_at: entry.checkedInAt,
    references:
      entry.references?.map((ref) => ({
        qualifier: ref.qualifier,
        value: ref.value
      })) ?? [],
    rejected_at: entry.rejectedAt,
    rejected_reasons: entry.rejectedReasons
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */

  const appointmentObj = appointment as any;
  for (const prop of objectKeys(appointment)) {
    if (appointment[prop] === '') {
      appointmentObj[prop] = null;
    }
  }

  /* eslint-enable @typescript-eslint/no-explicit-any */

  return appointment;
}

export function getTimeOfInterestBounds(selectedDate: Date, viewMode: ViewMode) {
  const date = new Date(selectedDate);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  const start = new Date(date);

  const end = new Date(date);
  if (viewMode === ViewMode.Day) {
    end.setDate(end.getDate() + 2);
    return [start, end];
  }

  end.setDate(start.getDate() + 7);
  return [start, end];
}

export function getCalendarTimeOfDayBounds(facility: Facility | null | undefined, selectedDate: Date): [Date, Date] {
  return [
    withTimeOfDay(selectedDate, facility?.first_appointment_start_time ?? '00:00'),
    withTimeOfDay(selectedDate, facility?.last_appointment_end_time ?? '24:00')
  ];
}

export function compareDocks(a: FacilityDock, b: FacilityDock): number {
  return compareHumanReadable(a.name, b.name) || cmp(a.id, b.id);
}

export function compareResources(a: ResourceStub, b: ResourceStub): number {
  return compareHumanReadable(a.title, b.title) || cmp(a.id, b.id);
}

export function getAppointmentMinutesAndLoadTypeId(
  appointment: AppointmentEntry,
  loadTypes: LoadType[],
  loadTypeDockRuleMatchResults?: LoadTypeDockRuleMatchResults
) {
  // default to 60 minutes in milliseconds;
  const defaultDuration = DefaultAppointmentDurationMinutes;
  // Check that matched_load_type_id is not null
  const matchingLoadTypeId = loadTypeDockRuleMatchResults?.matched_load_type_ids[0];
  if (appointment.status === AppointmentStatusEnum.Unscheduled) {
    // find the matching load type from the list of load types
    const matchingLoadType = loadTypes.find((loadType) => loadType.id === matchingLoadTypeId);

    // matching load type was found, return its appointment_duration in milliseconds
    if (matchingLoadType && matchingLoadType.appointment_duration) {
      const minutes = convertISO8601ToMinutes(matchingLoadType.appointment_duration);
      return {minutes, matchingLoadTypeId};
    }
    return {minutes: defaultDuration, matchingLoadTypeId};
  }
  if (appointment.appointmentType === FacilityAppointmentType.FirstComeFirstServe) {
    if (appointment.checkedInAt) {
      const start = new Date(appointment.checkedInAt.timestamp);
      let end: Date;
      if (appointment.rejectedAt) {
        end = new Date(appointment.rejectedAt.timestamp);
      } else if (appointment.checkedOutAt) {
        end = new Date(appointment.checkedOutAt.timestamp);
      } else {
        end = new Date(+start + DefaultAppointmentDurationMinutes * 60 * 1000);
      }
      return {minutes: Math.round((end.getTime() - start.getTime()) / (1000 * 60)), matchingLoadTypeId};
    }
  }
  // anything that didn't match a load type, but has a start and end time
  const duration = +new Date(appointment.end.timestamp) - +new Date(appointment.start.timestamp);
  // const freightAppointmentDuration =  appointment?.start && appointment.end ? duration
  return {minutes: duration / (1000 * 60), matchingLoadTypeId};
}

export function roundToAvailable(
  event: CalendarAppointmentEvent,
  windows: AppointmentAvailabilityWindow[]
): CalendarAppointmentEvent | null {
  const entry = windows
    .filter(
      (w) =>
        w.startDate.original < event.end &&
        event.start < w.endDate.original &&
        +event.end - +event.start <= +w.endDate.original - +w.startDate.original
    )
    .map((w) => ({
      w,
      d:
        Math.max(0, +w.startDate.original - +event.start) +
        Math.max(0, +event.end - +w.endDate.original) +
        (event.resourceId === FacilityAppointmentResourceId
          ? w.dockId
            ? 1_000_000
            : 0
          : event.resourceId === w.dockId
          ? 0
          : 1_000_000)
    }))
    .sort((a, b) => a.d - b.d)[0];
  if (!entry) {
    return null;
  }
  const {w} = entry;

  const resourceId = event.resourceId || w.dockId;
  let d = 0;
  if (w.startDate.original > event.start) {
    d = +w.startDate.original - +event.start;
  } else if (event.end > w.endDate.original) {
    d = +w.endDate.original - +event.end;
  } else if (resourceId !== event.resourceId) {
    d = 1;
  }
  if (d) {
    const start = new Date(+event.start + d);
    const end = new Date(+event.end + d);
    return {
      ...event,
      start,
      end,
      extendedProps: {
        ...event.extendedProps,
        appointment: {
          ...event.extendedProps.appointment,
          start: {
            ...event.extendedProps.appointment.start,
            timestamp: start.toISOString()
          },
          end: {
            ...event.extendedProps.appointment.end,
            timestamp: end.toISOString()
          },
          dockId: resourceId
        }
      },
      resourceId
    };
  }
  return event;
}

export function equipmentTypeName(equipmentType?: DockSchedulingEquipmentTypeEnum): string | undefined {
  if (!equipmentType) {
    return undefined;
  }
  const strIn = equipmentType as string;
  return strIn.toLowerCase().replace(/(?:_|^)([a-z])/g, (_, letter: string) => ` ${letter.toUpperCase()}`);
}

export function deliveryTypeName(deliveryType?: DeliveryTypeEnum): string | undefined {
  if (!deliveryType) {
    return undefined;
  }
  const strIn = deliveryType as string;
  return strIn.toLowerCase().replace(/(?:_|^)([a-z])/g, (_, letter: string) => ` ${letter.toUpperCase()}`);
}

export function translateReferenceType(referenceType: string): string {
  switch (referenceType) {
    case ReferenceQualifierEnum.PoNumber:
      return 'PO #';
    case ReferenceQualifierEnum.BolNumber:
      return 'BOL #';
    case ReferenceQualifierEnum.ProNumber:
      return 'Pro #';
    case ReferenceQualifierEnum.CustomerReferenceNumber:
      return 'Cust Ref #';
    case ReferenceQualifierEnum.ShipmentReferenceId:
      return 'Shipment ID';
    default:
      return referenceType; // Fallback to the original if no match
  }
}

export function formatDateRange(start: DateTimeWithTimezone, end: DateTimeWithTimezone): {date: string; time: string} {
  const startDate = moment.tz(start.timestamp, start.timezone);
  const endDate = moment.tz(end.timestamp, end.timezone);

  const formattedDate = startDate.format('dddd MMM D');

  // If both dates are on the same day
  if (startDate.isSame(endDate, 'day')) {
    const timeRange = `${startDate.format('hh:mm A')} - ${endDate.format('hh:mm A')}`;
    return {date: formattedDate, time: timeRange};
  }

  // If dates are on different days, format separately
  return {
    date: `${startDate.format('dddd MMM D')} - ${endDate.format('dddd MMM D')}`,
    time: `${startDate.format('hh:mm A')} - ${endDate.format('hh:mm A')}`
  };
}
