import moment from 'moment';
import {createColumnHelper, Table, Header} from '@tanstack/table-core';
import {ShippingDashboardRow, ShippingDashboardStop} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Checkbox} from '@shipwell/shipwell-ui';
import {ModeEnum} from '@shipwell/tabula-sdk';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import findLast from 'lodash/findLast';
import {Row} from '@tanstack/react-table';
import {CustomField} from '@shipwell/backend-core-sdk';
import {V3_VERSION} from './utils/constants';
import {getReadableCreatedByString} from 'App/utils/createdBy';
import {ContainerNumberWithLink} from 'App/containers/Dashboard/components/Columns/ContainerNumberCell';
import {
  AccessorialsCell,
  ActionsCell,
  AuctionStatsCell,
  CarrierCell,
  CarrierStatusCell,
  CurrencyCell,
  FinancialsCell,
  IdCell,
  ItemsCell,
  PoNumberCell,
  RepsCell,
  StatusCell,
  StopAddressCell,
  StopDateCell,
  StopsCell,
  DistanceCell,
  UpcomingEtasCell,
  WeightCell,
  WorkflowsCell,
  QuantityCell
} from 'App/containers/Dashboard/cells';
import {formatTime, formatTimeAgo} from 'App/utils/globals';
import {FlexBox} from 'App/components/Box';
import {
  renderCustomerFinancialsTable,
  renderNetFinancialsTable,
  renderProviderFinancialsTable
} from 'App/utils/shipmentTableHelpers/typed';
import {renderStopCompany} from 'App/utils/shipmentTableHelpers';
import {TableHeaderSortIcon} from 'App/components/TypedTable/baseComponents';
import {
  DashboardColumnIds,
  ShippingDashboardRowWithCustomDataSchema
} from 'App/containers/Dashboard/utils/DashboardUtilTypes';
import {formatShipmentModeCode} from 'App/utils/globalsTyped';

export const featureFlagDashboardColumnMap: Record<string, string[]> = {
  modeMultiModeDashboard: ['multi_stage_id', 'number_of_stages']
};

export const getUserPermissionsDashboardColumnMap = ({
  stmFinancialTenderingUserPermission
}: Record<string, boolean>): Record<string, string[]> => {
  //if the financial tendering flag is on, exclude the financials columns if user does not
  //have required permissions.
  if (stmFinancialTenderingUserPermission) {
    return {
      'shipments.view_financials': ['financials', 'provider_financials', 'customer_financials', 'net_financials']
    };
  }
  return {};
};

const columnHelper = createColumnHelper<ShippingDashboardRowWithCustomDataSchema>();

export const getColumnData = (customReferenceFields: CustomField[]) => [
  columnHelper.accessor('reference_id', {
    header: ({table, header}: {table: Table<ShippingDashboardRow>; header: Header<ShippingDashboardRow, unknown>}) => {
      const {getIsAllRowsSelected, getToggleAllPageRowsSelectedHandler, getState} = table;
      const {rowSelection} = getState();
      const isSomeSelected = Boolean(Object.keys(rowSelection).length);
      return (
        <FlexBox gap="s" items="center">
          <Checkbox
            aria-label="select all rows"
            checked={getIsAllRowsSelected()}
            indeterminate={isSomeSelected && !getIsAllRowsSelected()}
            fixedHeight={false}
            onChange={getToggleAllPageRowsSelectedHandler()}
          />
          <button onClick={header.column.getToggleSortingHandler()}>
            <TableHeaderSortIcon isSorted={header.column.getIsSorted()} />
          </button>
          <span>ID</span>
        </FlexBox>
      );
    },
    id: 'reference_id',
    cell: ({row}: {row: Row<ShippingDashboardRow>}) => {
      const {original, getCanSelect, getIsSelected, getToggleSelectedHandler} = row;
      return (
        <IdCell
          shipment={original}
          isSelected={getIsSelected()}
          disabled={!getCanSelect()}
          onSelect={getToggleSelectedHandler()}
        />
      );
    }
  }),
  columnHelper.accessor('v3_shipment_reference_id', {
    header: 'Multi-Stage ID',
    id: 'multi_stage_id',
    cell: (info) => <>{info.getValue() || '--'}</>,
    enableSorting: false
  }),
  columnHelper.accessor('number_of_stages', {
    header: 'Stages',
    id: 'number_of_stages',
    cell: (info) => <>{info.getValue() || '--'}</>,
    enableSorting: false
  }),
  columnHelper.accessor('state', {
    header: 'Status',
    id: 'status',
    enableSorting: true,
    cell: (info) => {
      const row = info.row.original;
      return <StatusCell shippingDashboardRow={row} />;
    }
  }),
  columnHelper.accessor('relationship_to_customer', {
    header: 'Customer',
    id: 'customer',
    enableSorting: true,
    cell: (info) => {
      const customerName = info.getValue()?.customer?.name;
      return <>{customerName || '--'}</>;
    }
  }),
  columnHelper.accessor('stops', {
    id: 'pickup_location',
    header: 'Pickup',
    cell: (info) => {
      const stops = info.getValue() || [];
      const firstPickup = stops
        .filter((stop) => stop.is_pickup)
        .sort((a, b) => a.ordinal_index - b.ordinal_index)
        .at(0);

      return <StopAddressCell stopLocation={firstPickup?.location} />;
    },
    enableSorting: false
  }),
  columnHelper.accessor('stops', {
    id: 'pickup',
    header: 'Pickup Date',
    cell: (info) => {
      const stops = info.getValue() || [];
      const stop = getStop(stops, 'pickup');
      const modeCode = info.row.original.mode?.code;
      const isLTL = getIsLTL(modeCode || '');
      return <StopDateCell stop={stop} usePlannedWindow={isLTL} />;
    }
  }),
  columnHelper.accessor('stops', {
    id: 'dropoff_location',
    header: 'Delivery',
    cell: (info) => {
      const stops = info.getValue() || [];
      const stop = getStop(stops, 'dropoff');
      return <StopAddressCell stopLocation={stop?.location} />;
    },
    enableSorting: false
  }),
  columnHelper.accessor('stops', {
    id: 'dropoff',
    header: 'Delivery Date',
    cell: (info) => {
      const stops = info.getValue() || [];
      const stop = getStop(stops, 'dropoff');
      return <StopDateCell stop={stop} />;
    },
    enableSorting: true
  }),
  columnHelper.accessor('stops', {
    id: 'stops',
    header: 'Stops',
    cell: (info) => <StopsCell stops={info.getValue()} mode={info.row.original.mode} />,
    enableSorting: false
  }),
  columnHelper.accessor('mode', {
    header: 'Mode',
    id: 'mode',
    enableSorting: true,
    cell: (info) => {
      const mode = info.getValue()?.code;
      const startCaseMode = formatShipmentModeCode(mode);
      return <>{startCaseMode || '--'}</>;
    }
  }),
  columnHelper.accessor('equipment_type', {
    header: 'Equipment',
    id: 'equipment_type',
    cell: (info) => <>{info.getValue()?.name || '--'}</>
  }),
  columnHelper.accessor('line_items', {
    id: 'weight',
    header: 'Weight',
    enableSorting: false,
    cell: (info) => <WeightCell shipment={info.row.original} />
  }),
  columnHelper.accessor('line_items', {
    id: 'quantity',
    header: 'Quantity',
    enableSorting: false,
    cell: (info) => <QuantityCell shipment={info.row.original} />
  }),
  columnHelper.accessor('created_at', {
    header: 'Created',
    id: 'created_at',
    cell: (info) => {
      return (
        <div className="flex flex-col">
          <span>{moment(info.getValue()).format('MMM D, YYYY')}</span>
          <span>{formatTime(info.getValue(), true, moment.tz.guess())}</span>
        </div>
      );
    }
  }),
  // TODO something in this Cell is causing lag
  columnHelper.accessor('id', {
    id: 'actions',
    header: 'Actions',
    enableSorting: false,
    cell: (info) => <ActionsCell shipment={info.row.original} />
  }),
  columnHelper.accessor('id', {
    id: 'auction_stats',
    header: 'Auction Stats',
    cell: (info) => {
      const shipmentId = info.getValue();
      return <AuctionStatsCell shipmentId={shipmentId} />;
    },
    enableSorting: false
  }),
  columnHelper.accessor('metadata.buy_it_now_amount', {
    id: 'book_now',
    header: 'Book Now',
    cell: (info) => {
      const buyNowAmount = info.getValue();
      return <CurrencyCell value={buyNowAmount} preferredCurrency={info.row.original.preferred_currency} />;
    }
  }),
  columnHelper.accessor('bol_number', {
    id: 'bol_number',
    header: 'BOL #',
    enableSorting: true,
    cell: (info) => {
      return info.getValue() || '--';
    }
  }),
  columnHelper.accessor('relationship_to_vendor', {
    id: 'carrier',
    header: 'Carrier',
    enableSorting: false,
    cell: (info) => {
      const relationshipToVendor = info.getValue();
      const shipment = info.row.original;
      return <CarrierCell shipment={shipment} relationshipToVendor={relationshipToVendor} />;
    }
  }),
  columnHelper.accessor('relationship_to_vendor.carrier_status', {
    id: 'carrier_status',
    header: 'Carrier Status',
    enableSorting: false,
    cell: (info) => {
      const carrierStatus = info.getValue();
      return <CarrierStatusCell status={carrierStatus} />;
    }
  }),
  columnHelper.accessor('drayage_container_return_date', {
    id: 'container_return_date',
    header: 'Container Return Date',
    cell: (info) => {
      const containerReturnDate = info.getValue();
      return formatDateCell(containerReturnDate);
    }
  }),
  columnHelper.accessor('drayage_container_number', {
    id: 'drayage_container_number',
    header: 'Container #',
    enableSorting: true,
    sortDescFirst: false,
    cell: (info) => {
      const containerNumber = info.getValue();
      const isV3 = info.row.original.version === V3_VERSION;
      const url = `/leg/${info.row.original.id}`;

      if (!containerNumber) {
        return '--';
      }
      if (!isV3) {
        return containerNumber;
      }
      return <ContainerNumberWithLink url={url} containerId={containerNumber || ''} />;
    }
  }),
  columnHelper.accessor('created_by_user', {
    id: 'created_by',
    header: 'Created By',
    enableSorting: false,
    cell: (info) => {
      const createdByUser = info.getValue();
      const createdBySource = info.row.original.created_by_source;
      const createdBy = getReadableCreatedByString(createdByUser, createdBySource);
      return <>{createdBy || '--'}</>;
    }
  }),
  columnHelper.accessor('id', {
    id: 'customer_financials',
    header: 'Customer Financials',
    enableSorting: false,
    cell: (info) => {
      const shipment = info.row.original;
      return <div className="grow text-right">{renderCustomerFinancialsTable(shipment)}</div>;
    }
  }),
  columnHelper.accessor('customer_reference_number', {
    id: 'customer_reference_number',
    header: 'Customer Reference #',
    enableSorting: true,
    sortDescFirst: false,
    cell: (info) => {
      return info.getValue() || '--';
    }
  }),
  columnHelper.accessor('total_miles', {
    id: 'total_miles',
    header: 'Distance',
    cell: (info) => <DistanceCell miles={info.getValue() || 0} />
  }),
  columnHelper.accessor('stops', {
    id: 'delivery_company',
    header: 'Delivery Company',
    enableSorting: false,
    cell: (info) => {
      const dropOffStops =
        info
          .getValue()
          ?.sort((a, b) => a.ordinal_index - b.ordinal_index)
          .filter((stop) => stop.is_dropoff) || [];
      const firstStop = dropOffStops[0];
      return firstStop?.location.company_name || '--';
    }
  }),
  columnHelper.accessor('drayage_estimated_arrival_date', {
    id: 'drayage_estimated_arrival_date',
    header: 'Estimated Port Arrival',
    cell: (info) => {
      const estimatedContainerArrival = info.getValue();
      return formatDateCell(estimatedContainerArrival);
    }
  }),
  columnHelper.accessor('id', {
    id: 'upcoming_etas',
    header: 'ETAs',
    enableSorting: false,
    cell: (info) => {
      const stops = info.row.original.stops || [];
      const modeCode = info.row.original.mode?.code;
      const isLTL = getIsLTL(modeCode || '');
      return <UpcomingEtasCell stops={stops} isLTL={isLTL} />;
    }
  }),
  columnHelper.accessor('id', {
    id: 'financials',
    header: 'Financials',
    enableSorting: false,
    cell: (info) => <FinancialsCell shipment={info.row.original} />
  }),
  columnHelper.accessor('drayage_house_bol_number', {
    id: 'drayage_house_bol_number',
    header: 'House BOL #',
    enableSorting: false,
    cell: (info) => {
      return info.getValue() || '--';
    }
  }),
  columnHelper.accessor('line_items', {
    id: 'items',
    header: 'Line Items',
    enableSorting: false,
    cell: (info) => {
      const lineItems = info.getValue() || [];
      const totalWeightOverride = info.row.original.total_weight_override;
      const shipmentId = info.row.original.id;
      return <ItemsCell lineItems={lineItems} totalWeight={totalWeightOverride} shipmentId={shipmentId} />;
    }
  }),
  columnHelper.accessor('drayage_last_free_date', {
    id: 'last_free_date',
    header: 'Last Free Date',
    enableSorting: false,
    cell: (info) => {
      const lastFreeDate = info.getValue();
      return formatDateCell(lastFreeDate);
    }
  }),
  columnHelper.accessor('metadata.load_board_id', {
    id: 'load_board_id',
    header: 'Load Board ID',
    enableSorting: false,
    cell: (info) => {
      return info.getValue() || '--';
    }
  }),
  columnHelper.accessor('auction_stats.lowest_bid', {
    id: 'lowest_bid',
    header: 'Lowest Bid',
    enableSorting: false,
    cell: (info) => {
      const lowestBid = info.getValue();
      const preferredCurrency = info.row.original.preferred_currency;
      return <CurrencyCell value={lowestBid} preferredCurrency={preferredCurrency} />;
    }
  }),
  columnHelper.accessor('markup', {
    id: 'markup',
    header: 'Markup',
    cell: (info) => {
      const markup = info.getValue();
      const preferredCurrency = info.row.original.preferred_currency;
      return <CurrencyCell value={markup} preferredCurrency={preferredCurrency} />;
    }
  }),
  columnHelper.accessor('metadata.max_buy_amount', {
    id: 'max_buy',
    header: 'Max Buy',
    cell: (info) => {
      const maxBuyAmount = info.getValue();
      const preferredCurrency = info.row.original.preferred_currency;
      return <CurrencyCell value={maxBuyAmount} preferredCurrency={preferredCurrency} />;
    }
  }),
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Name',
    cell: (info) => info.getValue() || '--'
  }),
  columnHelper.accessor('id', {
    id: 'net_financials',
    header: 'Net Financials',
    enableSorting: false,
    cell: (info) => {
      const shipment = info.row.original;
      return <span className="grow text-right">{renderNetFinancialsTable(shipment)}</span>;
    }
  }),
  columnHelper.accessor('stops', {
    id: 'pickup_company',
    header: 'Pickup Company',
    enableSorting: false,
    cell: (info) => {
      const pickupStop = getStop(info.getValue() || [], 'pickup');
      return renderStopCompany(pickupStop);
    }
  }),
  columnHelper.accessor('pickup_number', {
    id: 'pickup_number',
    header: 'Pickup #',
    enableSorting: true,
    sortDescFirst: false,
    cell: (info) => {
      return info.getValue() || '--';
    }
  }),
  columnHelper.accessor('purchase_order_number', {
    id: 'po_number',
    header: 'PO #',
    enableSorting: false,
    cell: (info) => {
      return <PoNumberCell poNumber={info.getValue() || ''} />;
    }
  }),
  columnHelper.accessor('power_unit_name', {
    id: 'power_unit_name',
    header: 'Power Unit',
    enableSorting: true,
    cell: (info) => info.getValue() || '--'
  }),
  columnHelper.accessor('pro_number', {
    id: 'pro_number',
    header: 'PRO #',
    enableSorting: false,
    cell: (info) => info.getValue() || '--'
  }),
  columnHelper.accessor('id', {
    id: 'provider_financials',
    header: 'Provider Financials',
    enableSorting: false,
    cell: (info) => {
      const shipment = info.row.original;
      return <div className="grow text-right">{renderProviderFinancialsTable(shipment)}</div>;
    }
  }),
  columnHelper.accessor('drayage_release_date', {
    id: 'release_date',
    header: 'Release Date',
    enableSorting: false,
    cell: (info) => {
      const drayageReleaseDate = info.getValue();
      return moment(drayageReleaseDate).isValid() ? moment(drayageReleaseDate).format('MMM D, YYYY') : '--';
    }
  }),
  columnHelper.accessor('reps', {
    id: 'reps',
    header: 'Reps',
    enableSorting: false,
    cell: (info) => {
      const reps = info.getValue();
      return <RepsCell reps={reps || []} />;
    }
  }),
  columnHelper.accessor('drayage_seal_number', {
    id: 'drayage_seal_number',
    header: 'Seal #',
    enableSorting: true,
    sortDescFirst: false,
    cell: (info) => {
      return info.getValue() || '--';
    }
  }),
  columnHelper.accessor('calculated_ui_service_level', {
    id: 'calculated_ui_service_level',
    header: 'Service Level',
    cell: (info) => <>{info.getValue() || '--'}</>
  }),
  columnHelper.accessor('accessorials', {
    id: 'shipment_accessorials',
    header: 'Shipment Accessorials',
    enableSorting: false,
    cell: (info) => {
      const accessorials = info.getValue() || [];
      return <AccessorialsCell accessorials={accessorials} />;
    }
  }),
  columnHelper.accessor('metadata.target_rate_amount', {
    id: 'target_rate_amount',
    header: 'Target Rate',
    enableSorting: false,
    cell: (info) => {
      const targetRate = info.getValue();
      const preferredCurrency = info.row.original.preferred_currency;
      return <CurrencyCell value={targetRate} preferredCurrency={preferredCurrency} />;
    }
  }),
  columnHelper.accessor('tender_status', {
    id: 'tender_status',
    header: 'Tender Status',
    cell: (info) => capitalize(info.getValue()) || '--'
  }),
  columnHelper.accessor('timeline_last_updated_at', {
    id: 'timeline_last_updated_at',
    header: 'Timeline Updated',
    cell: (info) => {
      return formatTimeAgo(info.getValue());
    }
  }),
  columnHelper.accessor('trailer_name', {
    id: 'trailer_name',
    header: 'Trailer #',
    enableSorting: true,
    sortDescFirst: false,
    cell: (info) => info.getValue() || '--'
  }),
  columnHelper.accessor('id', {
    id: 'workflows',
    header: 'Workflows',
    cell: (info) => {
      const shipmentId = info.getValue();
      return <WorkflowsCell shipmentId={shipmentId} />;
    },
    enableSorting: false
  }),
  columnHelper.accessor('references', {
    id: 'MASTER_AIR_WAYBILL',
    header: 'Master Airway Bill Number',
    enableSorting: false,
    cell: ({row}: {row: Row<ShippingDashboardRow>}) => {
      const {original} = row;
      return original?.references?.find((ref) => ref.qualifier === 'MASTER_AIR_WAYBILL')?.value || '--';
    }
  }),
  columnHelper.accessor('references', {
    id: 'HOUSE_AIR_WAYBILL',
    header: 'House Airway Bill Number',
    enableSorting: false,
    cell: ({row}: {row: Row<ShippingDashboardRow>}) => {
      const {original} = row;
      return original?.references?.find((ref) => ref.qualifier === 'HOUSE_AIR_WAYBILL')?.value || '--';
    }
  }),
  ...customReferenceFields.map((customField) =>
    columnHelper.accessor('custom_data', {
      meta: {customFieldType: 'custom_data'},
      header: customField.label,
      id: customField.name,
      cell: (info) => {
        const customFieldValue = info.getValue()?.shipwell_custom_data?.shipment?.[customField.id || ''];
        return <>{customFieldValue || '--'}</>;
      },
      enableSorting: false
    })
  )
];

const getStop = (stops: ShippingDashboardStop[], kind: 'pickup' | 'dropoff') => {
  const findFunction = kind === 'pickup' ? find : findLast;
  const sortedStops = stops?.sort((a, b) => a.ordinal_index - b.ordinal_index);
  const desiredStop = findFunction(sortedStops, (sortedStop) =>
    kind === 'pickup' ? sortedStop.is_pickup : sortedStop.is_dropoff
  );
  return desiredStop;
};

const formatDateCell = (dateString?: string | null) => {
  const isValid = moment(dateString).isValid();
  if (!isValid) {
    return '--';
  }
  return moment(dateString).format('MMM D, YYYY');
};

export const getIsLTL = (modeCode: string) => modeCode === ModeEnum.Ltl || modeCode === ModeEnum.Vltl;

export const defaultKeys: DashboardColumnIds[] = [
  'reference_id',
  'multi_stage_id',
  'number_of_stages',
  'status',
  'customer',
  'pickup_location',
  'pickup',
  'dropoff_location',
  'dropoff',
  'stops',
  'mode',
  'equipment_type',
  'quantity',
  'weight',
  'created_at',
  'actions'
];

export const nonDefaultKeys: DashboardColumnIds[] = [
  'auction_stats',
  'book_now',
  'bol_number',
  'carrier',
  'carrier_status',
  'container_return_date',
  'drayage_container_number',
  'created_by',
  'customer_financials',
  'customer_reference_number',
  'delivery_company',
  'drayage_estimated_arrival_date',
  'financials',
  'drayage_house_bol_number',
  'items',
  'last_free_date',
  'load_board_id',
  'lowest_bid',
  'markup',
  'max_buy',
  'total_miles',
  'name',
  'net_financials',
  'pickup_company',
  'trailer_name',
  'pickup_number',
  'po_number',
  'power_unit_name',
  'pro_number',
  'provider_financials',
  'release_date',
  'reps',
  'drayage_seal_number',
  'shipment_accessorials',
  'target_rate_amount',
  'tender_status',
  'timeline_last_updated_at',
  'upcoming_etas',
  'workflows',
  'calculated_ui_service_level',
  'MASTER_AIR_WAYBILL',
  'HOUSE_AIR_WAYBILL'
];

export const initialColumnVisibility = nonDefaultKeys.reduce<Record<string, boolean>>((keyObject, key) => {
  keyObject[key] = false;
  return keyObject;
}, {});
