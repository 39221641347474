import {useMutation, useQuery} from '@tanstack/react-query';
import {ConstraintsFormValues} from '../useConstraintsForm/constraintsFormValue';
import {SaveParams as SaveMutationParams} from './types';
import {getOptimizationCategoriesFromLogicalConstraints} from './converters/optimizationCategoy';
import {getLogicalConstraintValue, getUniqueSlugs} from './converters/logicalConstrants';
import {getCategoryConstraintsGet, updateCategoryConstraintsPut} from 'App/api/loadOptimization';
import {listTags} from 'App/api/corrogo/tags';

export interface UseConstraintsServiceProps {
  companyId?: string;
}

export function useConstraintsService(props: UseConstraintsServiceProps) {
  const getQuery = useQuery<ConstraintsFormValues>({
    queryKey: ['companyConstraintsQuery', props.companyId],
    queryFn: async () => {
      if (!props.companyId) return Promise.resolve({constraints: []});
      const constraintsResponse = await getCategoryConstraintsGet(props.companyId);
      const uniqueSlugs = getUniqueSlugs(constraintsResponse.data.data);
      const tagsResponse = await listTags({slugIn: uniqueSlugs.join(',')});
      const values = getLogicalConstraintValue(constraintsResponse.data.data, tagsResponse.data.data);
      return {
        constraints: values
      };
    }
  });

  const saveMutation = useMutation({
    mutationFn: async (params: SaveMutationParams) => {
      if (!props.companyId) return;
      const convertedData = getOptimizationCategoriesFromLogicalConstraints(params.values);
      await updateCategoryConstraintsPut(params.companyId, {
        category_constraints: convertedData
      });
    }
  });

  const loading = getQuery.isFetching || saveMutation.isLoading;

  return {
    data: getQuery.data,
    loading,
    save: saveMutation.mutateAsync
  };
}
