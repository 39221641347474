import {useQuery} from '@tanstack/react-query';
import {getCompanySettingsList} from '..';
import {COMPANY_LOAD_OPTIMIZATION_CONFIGURATIONS_QUERY_KEY} from 'App/data-hooks/queryKeys';
const useLoadOptimizationConfigListQuery = (listViewOptions = {}, queryOptions = {}) =>
  useQuery(
    [COMPANY_LOAD_OPTIMIZATION_CONFIGURATIONS_QUERY_KEY, listViewOptions],
    async () => {
      const response = await getCompanySettingsList(listViewOptions);
      return response?.data ?? {};
    },
    {
      ...queryOptions
    }
  );
export default useLoadOptimizationConfigListQuery;
