import {
  DistanceUnitType,
  PackageType,
  SpeedUnitType,
  VolumeUnitType,
  WeightUnitType
} from '@shipwell/shipment-assembly-sdk';
import {CompanyLoadOptimizationFormValues} from './types';

export const defaultConfigValues: CompanyLoadOptimizationFormValues = {
  setting_name: '',
  resource_type: 'company',
  minimum_truck_weight: 15000,
  maximum_truck_weight: 45000,
  weight_unit: WeightUnitType.Lb,
  minimum_truck_volume: 1000,
  maximum_truck_volume: 3000,
  volume_unit: VolumeUnitType.Ft,
  minimum_package_count: 10,
  maximum_package_count: 24,
  package_type: PackageType.Plt,
  minimum_stop_time_seconds: 7200,
  maximum_stops: 4,
  stop_charges_dollars: 100,
  frozen_as_last_stop: false,
  average_truck_speed: 50,
  speed_unit: SpeedUnitType.Mph,
  maximum_distance_per_route: undefined,
  distance_unit: DistanceUnitType.Miles,
  maximum_time_per_route_seconds: undefined,
  max_truck_count: undefined,
  facility_open_time: '07:00',
  facility_close_time: '19:00',
  open_sunday: false,
  sunday_open_time: '07:00',
  sunday_close_time: '19:00',
  open_monday: false,
  monday_open_time: '07:00',
  monday_close_time: '19:00',
  open_tuesday: false,
  tuesday_open_time: '07:00',
  tuesday_close_time: '19:00',
  open_wednesday: false,
  wednesday_open_time: '07:00',
  wednesday_close_time: '19:00',
  open_thursday: false,
  thursday_open_time: '07:00',
  thursday_close_time: '19:00',
  open_friday: false,
  friday_open_time: '07:00',
  friday_close_time: '19:00',
  open_saturday: false,
  saturday_open_time: '07:00',
  saturday_close_time: '19:00',
  return_to_pickup: false,
  default_days_until_pickup: 1,
  default_pickup_time: '07:00',
  ltl_freight_class: '100',
  ltl_discount_percentage: 25,
  local_delivery: false,
  custom_reference_pallet_override: undefined,
  category_constraints: []
};

export const DEFAULT_LOAD_OPTIMIZATION_CONFIG_SETTING_NAME = 'Default';

export const LOAD_OPTIMIZATION_CONFIG_SAVE_SUCCESS_MESSAGE = 'Your load optimization settings were saved successfully.';
export const LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_MESSAGE =
  'Your load optimization settings were not saved successfully. Try again.';
export const LOAD_OPTIMIZATION_CONFIG_SAVE_SUCCESS_TITLE = 'Success!';
export const LOAD_OPTIMIZATION_CONFIG_SAVE_ERROR_TITLE = 'Something Went Wrong!';
export const LOAD_OPTIMIZATION_CONFIG_DELETE_SUCCESS_MESSAGE =
  'Your load optimization settings were deleted successfully.';
export const LOAD_OPTIMIZATION_CONFIG_DELETE_ERROR_MESSAGE =
  'Your load optimization settings were not deleted successfully. Try again.';
